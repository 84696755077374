import AppleIcon from "@mui/icons-material/Apple";
import GoogleIcon from "@mui/icons-material/Google";
import ContactlessIcon from "@mui/icons-material/Contactless";
import AnchorIcon from "@mui/icons-material/Anchor";
import { FooterListButton } from "../../components/UIComponents/Buttons";
import { FooterListTitle } from "../../components/UIComponents/Typography";
import useMediaQuery from "../../hooks/useMediaQuery";

export default function FooterPodcastList() {
  const isDesktop = useMediaQuery("(min-width: 960px)");
  return (
    <ul className="prose dark:prose-invert max-w-none prose-reset-list prose-hr:m-0 prose-link prose-a:font-normal w-full lg:w-64 m-0 p-0">
      <div className={isDesktop ? "" : "hidden"}>
        <FooterListTitle>Podcast</FooterListTitle>
      </div>
      <FooterListButton href="https://podcasts.apple.com/us/podcast/the-forgotten-adoption-option-podcast/id1542072701">
        <AppleIcon />
        Apple
      </FooterListButton>
      <FooterListButton href="https://podcasts.google.com/feed/aHR0cHM6Ly9hbmNob3IuZm0vcy8zYTY2ZDNiOC9wb2RjYXN0L3Jzcw==">
        <GoogleIcon />
        Google
      </FooterListButton>
      <FooterListButton href="https://open.spotify.com/show/0ugiSikvsI4oOxzALI9FZv">
        <ContactlessIcon />
        Spotify
      </FooterListButton>
      <FooterListButton href="https://anchor.fm/marcy-bursac">
        <AnchorIcon />
        Anchor
      </FooterListButton>
    </ul>
  );
}
