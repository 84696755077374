import { contentTypes } from "../../utility/constants";

export default {
  type: contentTypes.TEXT,
  title: "Request Adoption Benefits From Your Employer",
  content: (
    <article>
      <p>
        <strong>Adoption Leave</strong>
      </p>
      <p>
        My husband and I talked to our employers about our intent to adopt
        through foster care. I used materials from The Dave Thomas Foundation
        for Adoption to craft a proposal for the adoption benefits I wanted my
        employer to provide. My husband’s employer offered a certain number of
        days off to him. My employer offered me a week of adoption leave
        whenever I wanted to take it and excused me from a board meeting that
        conflicted with the final adoption training.
      </p>
      <p>Some companies call this “Parental Leave” or another name.</p>
      <p>
        If your company does not offer any type of adoption benefits, you
        can&nbsp;
        <a
          href="https://www.davethomasfoundation.org/library/sample-adoption-benefits-proposal-for-employees/"
          target="_blank"
          rel="noopener noreferrer"
        >
          use this template
        </a>
        &nbsp;from the Dave Thomas Foundation for Adoption to request that the
        company become an Adoption-Friendly Workplace.
      </p>
      <p>
        <a
          href="https://www.davethomasfoundation.org/library/adoption-friendly-workplace-employer-toolkit/"
          target="_blank"
          rel="noopener noreferrer"
        >
          This toolkit
        </a>
        &nbsp;(which was also created by the Dave Thomas Foundation for
        Adoption) has everything a company needs to begin considering improving
        or adding adoption benefits.
      </p>
    </article>
  ),
};
