import { Link } from "react-router-dom";
import { Container } from "../components/UIComponents/Container";
import Breadcrumbs from "../components/UIComponents/Breadcrumbs";
import { ArticleHeader } from "../components/UIComponents/Typography";

export default function QuickLinks() {
  return (
    <main>
      <Container className="flex flex-col flex-nowrap justify-center max-w-[1200px] w-[95%] md:w-[80%] mb-32">
        <article className="relative">
          <Breadcrumbs
            separator="›"
            crumbs={["Home", "Get Started", "Quick Links"]}
          />

          <ArticleHeader>Quick Links</ArticleHeader>

          <p>
            <Link to="/steps/step1Task3">Common Fears</Link>
          </p>

          <p>
            <Link to="/steps/step2Task4">The Application</Link>
          </p>

          <p>
            <Link to="/steps/step2Task3">Local Training</Link>
          </p>

          <p>
            <Link to="/steps/step2Task5">
              Things You’re Going to Need to Turn In
            </Link>
          </p>

          <p>
            <Link to="/steps/step5Task2">Family Book Example</Link>
          </p>

          <p>
            <Link to="/steps/step6Task4">Resources You Might Want to Use</Link>
          </p>
        </article>
      </Container>
    </main>
  );
}
