import { contentTypes } from "../../utility/constants";
import { TextCaption } from "../../components/UIComponents/Typography";

export default {
  type: contentTypes.TEXT,
  title: "Become aware of what foster care adoption is",
  content: (
    <article>
      <p>
        What is foster care adoption? First, let’s take a look at the reasons
        why foster care exists.
      </p>
      <p>
        In a sentence, when a child’s home life is unsafe, dangerous or abusive,
        in order for the child to be safe and free of danger or abuse, the child
        needs a place to live.
      </p>
      <p>
        In the 1800’s in the United States, if a child’s family could not take
        care of them they were sent to live with a relative or neighbor.
        <sup>[1]</sup> As more and more people immigrated to the United States,
        orphanages sprouted up around the country as a way to provide a safe
        haven for groups of children who lost their parents due to illness and
        for children who were given up by their families because the family was
        in poverty or struggling with drugs or alcohol addiction.<sup>[2]</sup>
      </p>
      <p>
        About 100 years later, in the 1920’s, after seeing the positive
        differences between children living with a family versus living in a
        group setting, foster homes emerged in the United States.
        <sup>[3]</sup>
        The intent of foster homes was and continues to be to provide children
        with “a safe, supportive and loving home” ... “while their parents of
        origin improve their ability to care for their children, remove any
        threats to the child’s safety and well-being from the environment and,
        ultimately, reunite with their children”.<sup>[4]</sup>
      </p>
      <p>
        You read that right, the goal of foster care is to have children move
        back in with their biological families. Sometimes, just like New Year’s
        Resolutions, goals are goals that never materialize into real-life
        achievements. Children who move into a foster home may never move back
        in with their biological families, or at least permanently. This occurs,
        for instance, when the biological parents are unable to develop the
        appropriate parenting skills, become safe, or relapse over and over
        again on a drug addiction.<sup>[5]</sup>
      </p>
      <p>
        Some children become legally free for adoption while in foster care. Let
        me say this a different way,{" "}
        <strong>
          there are children who are ready to be adopted and you can adopt them
          without being a foster parent.
        </strong>{" "}
        That means their biological parents lose the ability to be the child’s
        parents and the state takes over as the child’s legal guardian until the
        child is adopted. The need for adoptive homes is great. At the time of
        launching this app, more than 113,000 children are in this situation.
        The ages of the children are as young as just born to a senior in high
        school at age 18. Some are all by themselves others are with a sibling
        or two or more. <br />
      </p>

      <p>
        [1]{" "}
        <TextCaption>
          American Adoptions. (2020). Do orphanages still exist in America? The
          truth about “adopting an orphan”. Retrieved August 2, 2020 from:
          https://www.americanadoptions.com/adoption/do-orphanages-still-exist.
        </TextCaption>
        <br />
        [2]{" "}
        <TextCaption>
          American Adoptions. (2020). Do orphanages still exist? The truth about
          “adopting an orphan”. Retrieved August 2, 2020 from:
          https://www.americanadoptions.com/adoption/do-orphanages-still-exist.
        </TextCaption>
        <br />
        [3]{" "}
        <TextCaption>
          Children Welfare Information Gateway. (n.d.). Children’s bureau
          history with foster care. Retrieved August 2, 2020 from:
          https://www.childwelfare.gov/fostercaremonth/about/history/.
        </TextCaption>
        <br />
        [4]{" "}
        <TextCaption>
          FosterMore. (2020). What is foster care? Retrieved August 2, 2020
          from: https://fostermore.org/what-is-foster-care.
        </TextCaption>
        <br />
        [5]{" "}
        <TextCaption>
          Craft, C. (2020, June 13). Reasons children enter the foster care
          system. Very Well Family. Retrieved August 2, 2020 from:
          https://www.verywellfamily.com/top-reasons-children-enter-foster-care-27123.
        </TextCaption>
      </p>
    </article>
  ),
};
