import { useLocation } from "react-router";
import * as Accordion from "@radix-ui/react-accordion";
import stepSummary from "../content/stepSummary";
import Step from "./Step";
import useMediaQuery from "../hooks/useMediaQuery";

export default function Steps() {
  const location = useLocation();
  const isMobile = useMediaQuery("(max-width: 640px)");
  return (
    <aside
      data-hidden={
        location.pathname === "/steps" || location.pathname === "/" || !isMobile
      }
    className="data-[hidden=false]:hidden prose dark:prose-invert prose-reset-list prose-reset-headings prose-reset-text prose-h3:font-thin prose-a:font-normal prose-headings:text-white dark:prose-headings:text-background dark:prose-p:text-primary w-full min-h-full sm:col-span-4">
      <Accordion.Root
        type="single"
        collapsible
        className="flex flex-col gap-4 p-4 pb-24 md:p-0"
      >
        {stepSummary.map((step, stepIdx) => (
          <Step key={step.title} step={step} stepIdx={stepIdx} />
        ))}
      </Accordion.Root>
    </aside>
  );
}
