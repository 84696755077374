import React from "react";

export default function Breadcrumbs({
  crumbs,
  separator,
}: {
  crumbs: string[];
  separator: string;
}) {
  return (
    <div
      aria-label="breadcrumb"
      className="absolute top-0 right-0 flex items-center gap-2 text-zinc-500"
    >
      {crumbs.map((item, index) => (
        <React.Fragment key={item}>
          <span className="text-xs py-2">{item}</span>
          {index < crumbs.length - 1 && <span>{separator}</span>}
        </React.Fragment>
      ))}
    </div>
  );
}
