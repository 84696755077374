import { iDefaultProp } from "../../types/interfaces";
/* eslint-disable import/prefer-default-export */
export function TextCaption({ children, className }: iDefaultProp) {
  return <span className={`${className} text-sm`}>{children}</span>;
}

export function ArticleHeader({ children, className }: iDefaultProp) {
  return (
    <h2
      className={`${className} w-fit px-2 pb-2 border-b-4 border-primary font-thin text-4xl`}
    >
      {children}
    </h2>
  );
}

export function FooterListTitle({ children, className }: iDefaultProp) {
  return (
    <h4
      className={`${className} pl-4 pb-2 sm:mt-4 sm:mb-0 text-xl font-thin border-b-2 sm:border-b-4 border-primary`}
    >
      {children}
    </h4>
  );
}
