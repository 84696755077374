import { contentTypes } from "../../utility/constants";

export default {
  type: contentTypes.PODCAST,
  title: "Worth Listening",
  playlist:
    "https://open.spotify.com/embed/playlist/2FeDGu1CdcZWXFxtr3kCZO?utm_source=generator",
  resources: [
    {
      title: "Meet Nathan - Adoptive Dad (who also happens to be my husband!)",
      links: [
        {
          link: "https://podcasts.apple.com/us/podcast/meet-nathan-adoptive-dad-who-also-happens-to-be-my-husband/id1542072701?i=1000501275195",
          platform: "apple",
        },
        {
          link: "https://spotifyanchor-web.app.link/e/VWi7hWPj7sb",
          platform: "spotify",
        },
      ],
    },
    {
      title: "Meet Ben & Emily: Soon-To-Be Adoptive Parents",
      links: [
        {
          link: "https://podcasts.apple.com/us/podcast/how-you-can-obtain-required-training-hours-without/id1542072701?i=1000553294243",
          platform: "apple",
        },
        {
          link: "https://spotifyanchor-web.app.link/e/RcvEcEFOstb",
          platform: "spotify",
        },
      ],
    },
    {
      title: "HOW to understand the unmet needs of children in foster care",
      links: [
        {
          link: "https://podcasts.apple.com/us/podcast/how-to-understand-the-unmet-needs-of-children-in-foster-care/id1542072701?i=1000575516649",
          platform: "apple",
        },
        {
          link: "https://spotifyanchor-web.app.link/e/3lqfZwFOstb",
          platform: "spotify",
        },
      ],
    },
    {
      title:
        "HOW to verbalize and process fears associated with adopting children in foster care",
      links: [
        {
          link: "https://podcasts.apple.com/us/podcast/how-to-verbalize-and-process-fears-associated/id1542072701?i=1000565501598",
          platform: "apple",
        },
        {
          link: "https://spotifyanchor-web.app.link/e/yoVCyyFOstb",
          platform: "spotify",
        },
      ],
    },
  ],
};
