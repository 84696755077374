import { contentTypes } from "../../utility/constants";

export default {
  type: contentTypes.TEXT,
  title: "Announce to Friends & Family",
  content: (
    <article>
      <p>
        By this step, you might have already told close family and/or friends
        about your desire to adopt or foster. Now might feel like the right time
        to tell your wider circle.{" "}
        <a
          href="https://drive.google.com/file/d/1N7RhYX242Vm79UxuEL_dIP9-wfCtDQym/view"
          target="_blank"
          rel="noopener noreferrer"
        >
          My husband and I wrote a letter
        </a>{" "}
        and mailed it to close family and friends to invite them to support us.
        Later{" "}
        <a
          href="https://drive.google.com/file/d/1S0XkNZ0gasoYmhMIQ-FQAqZZwRMvfeui/view?usp=sharing"
          target="_blank"
          rel="noopener noreferrer"
        >
          we made an announcement on social media
        </a>{" "}
        and included{" "}
        <a
          href="https://drive.google.com/file/d/1rVVuK_16NDi7wQjlvgzlRDsZV2T8OLRt/view?usp=sharing"
          target="_blank"
          rel="noopener noreferrer"
        >
          this image
        </a>{" "}
        that my husband made. Just know: telling invites people to ask
        questions, so be ready for people’s opinions, curiosities, and
        unsolicited advice.
      </p>
    </article>
  ),
};
