import { Configuration, OpenAIApi } from "openai";

const configuration = new Configuration({
  apiKey: process.env.REACT_APP_OPENAI_API_KEY,
});

const openai = new OpenAIApi(configuration);

export default async function fetchReply(prompt: string) {
  try {
    const response = await openai.createCompletion({
      model: `${process.env.REACT_APP_OPENAI_API_MODEL}`,
      prompt,
      presence_penalty: 0,
      frequency_penalty: 0.3,
      max_tokens: 100,
      temperature: 0,
      stop: ["\n", "->", " END"],
    });

    return `${response.data.choices[0].text}`;
  } catch (error) {
    return `${error}`;
  }
}
