import { contentTypes } from "../../utility/constants";

export default {
  type: contentTypes.TEXT,
  title: "Serve as a Respite Provider",
  content: (
    <article>
      <p>
        One way to help children in foster care is by serving as a respite
        provider. Essentially you would be hosting a child(ren) in your home for
        short, defined periods of time. My husband and I helped a couple who
        needed to travel out of town by taking care of their two boys (who they
        were fostering) for a few days. We also helped another foster family by
        taking care of one of their foster children for a few days, twice. My
        experience as a respite provider deepened my heart for children in
        foster care, increased my understanding of how to parent with healthy
        boundaries and a routine, and built friendships with other families who
        were in a similar lifestyle.
      </p>
      <p>
        To help the child(ren) continue their same routine and to put the foster
        parents’ minds at ease,{" "}
        <a
          href="https://docs.google.com/document/d/1xJcxBVxz_ijoroVoKFS2WBQOIJQxvkpZ/edit?usp=sharing&ouid=109554470457385909219&rtpof=true&sd=true"
          target="_blank"
          rel="noopener noreferrer"
        >
          I put together these questions
        </a>
        . In a phone call prior to the child(ren) staying with us, I would go
        over these questions with the foster parent(s).
      </p>
    </article>
  ),
};
