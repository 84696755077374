import { contentTypes } from "../../utility/constants";

export default {
  type: contentTypes.TEXT,
  title: "Create an Emergency Plan",
  content: (
    <article>
      <p>
        Over the course of your licensing classes you’re going to be asked to
        fill out&nbsp;
        <a
          href="https://docs.google.com/file/d/1J-cTBdMn3xH08waUumyij3S_wTsIhHTs/edit?usp=docslist_api&filetype=msword"
          target="_blank"
          rel="noopener noreferrer"
        >
          a form like this
        </a>
        . You’re also going to be asked to draw an emergency exit plan of your
        home&nbsp;
        <a
          href="https://drive.google.com/file/d/19w-1S1PP4X33_JRt_reEi88oFtVQbq_G/view?usp=sharing"
          target="_blank"
          rel="noopener noreferrer"
        >
          using a template like this
        </a>
        .
      </p>

      <p>
        We were required to do an emergency drill with our children monthly once
        they were living with us before the adoption was finalized.
      </p>
    </article>
  ),
};
