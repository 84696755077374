import * as Accordion from "@radix-ui/react-accordion";
import { KeyboardArrowDown } from "@material-ui/icons";
import { Link, useLocation } from "react-router-dom";
import { titleCase } from "title-case";

interface taskTitleTime {
  taskTitle?: string;
  taskTime?: number;
}
interface iStep {
  step: {
    step: number;
    altText: string;
    number: number;
    title: string;
    tasks: number;
    taskTitlesTimes: taskTitleTime[];
  };
  stepIdx: number;
}

export default function Step({ step, stepIdx }: iStep) {
  const { title, taskTitlesTimes } = step;

  // Fix missing route path
  const { pathname } = useLocation();
  const correctPath = pathname.includes("/steps") ? "" : "steps/";

  return (
    <Accordion.Item
      value={`step-${stepIdx + 1}`}
      className="flex flex-col data-[state=open]:motion-reduce:my-2 data-[state=open]:duration-75 data-[state=open]:ease-in-out"
    >
      <Accordion.Trigger className="flex justify-between items-center px-6 py-3 text-left rounded-md data-[state=open]:rounded-b-none data-[state=open]:motion-reduce:animate-expandDown shadow-sm bg-primary text-background">
        <>
          <div className="flex gap-6 place-items-center">
            <div className="relative flex place-items-center place-content-center before:content-[' '] before:absolute before:p-[16px] before:rounded-full before:ring-2 before:ring-background before:bg-primary after:content-[' '] after:absolute after:p-[14px] after:rounded-full after:bg-background">
              <p className="z-[1] text-2xl font-extrabold text-primary">
                {stepIdx + 1}
              </p>
            </div>
            <h3>{title}</h3>
          </div>
          <KeyboardArrowDown />
        </>
      </Accordion.Trigger>
      <Accordion.Content className="flex flex-col shadow-md bg-background motion-reduce:animate-slide-down">
        {taskTitlesTimes.map(({ taskTitle, taskTime }, taskIdx) => {
          const taskId = `step${stepIdx + 1}Task${taskIdx + 1}`;

          function displayReadTime(): string {
            if (taskTime && taskTime >= 2) {
              return `${taskTime} minutes read`;
            }
            if (taskTime && taskTime < 2 && taskTime > 0) {
              return `${taskTime} minute read`;
            }
            return "less than 1 minute read";
          }

          return (
            <Link
              key={taskId}
              type="button"
              className="text-left group px-4 py-2 no-underline hover:bg-primary-100 border-l-8 hover:border-l-8 border-background/0 hover:border-primary"
              to={correctPath + taskId}
            >
              <p className="text-left text-primary no-underline group-hover:underline">
                {taskTitle && titleCase(taskTitle)}
              </p>
              <p className="font-extralight text-sm text-gray-400">
                {displayReadTime()}
              </p>
            </Link>
          );
        })}
      </Accordion.Content>
    </Accordion.Item>
  );
}
