import EmailIcon from "@mui/icons-material/Email";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import FacebookIcon from "@mui/icons-material/Facebook";
import { FooterListButton } from "../../components/UIComponents/Buttons";
import { FooterListTitle } from "../../components/UIComponents/Typography";
import useMediaQuery from "../../hooks/useMediaQuery";

export default function FooterContactList() {
  const isDesktop = useMediaQuery("(min-width: 960px)");
  return (
    <ul className="prose dark:prose-invert max-w-none prose-reset-list prose-hr:m-0 prose-link prose-a:font-normal w-full lg:w-64 m-0 p-0">
      <FooterListTitle className={isDesktop ? "" : "hidden"}>
        Contact
      </FooterListTitle>
      <FooterListButton href="mailto:forgottenadoptionoption@gmail.com">
        <EmailIcon />
        Email
      </FooterListButton>
      <FooterListButton href="https://www.instagram.com/marcybursac/">
        <InstagramIcon />
        Instagram
      </FooterListButton>
      <FooterListButton href="https://www.linkedin.com/in/marcybursac/">
        <LinkedInIcon />
        Linkedin
      </FooterListButton>
      <FooterListButton href="https://www.facebook.com/msbursac">
        <FacebookIcon />
        Facebook
      </FooterListButton>
    </ul>
  );
}
