import Breadcrumbs from "../components/UIComponents/Breadcrumbs";
import { Container } from "../components/UIComponents/Container";
import { ArticleHeader } from "../components/UIComponents/Typography";

export default function GetFeetWet() {
  return (
    <main>
      <Container className="flex flex-col flex-nowrap justify-center max-w-[1200px] w-[95%] md:w-[80%]">
        <article className="relative">
          <Breadcrumbs
            crumbs={["Home", "Get Started", "Get Your Feet Wet"]}
            separator=">"
          />

          <ArticleHeader>Get Your Feet Wet</ArticleHeader>

          <p>
            Deciding to adopt is a big decision. It’s a decision that takes many
            around five years to start thinking about before taking action. To
            help you get experience and aid in your decision to adopt or not,
            consider these opportunities:
          </p>

          <h2>Respite</h2>

          <p>
            Respite is a way to provide a break for foster parents. You take
            child(ren) for an overnight to up to two weeks at a time. Foster
            Parents are given so many days of respite care per calendar year.
            Read: You get paid to be a respite provider for each day of care you
            provide. Respite is a great place to start if you are unsure and/or
            if you want to pursue adopt-only (again this is known as
            foster-to-adopt in some areas) but you are not currently a parent.
            My husband and I were respite providers and took care of a pair of
            brothers and a single boy on a few occasions. We provided respite
            care for five days once, three days once, and then two days once.
          </p>

          <p>
            To become a respite provider, contact a{" "}
            <a
              href="https://www.adoptuskids.org/adoption-and-foster-care/how-to-adopt-and-foster/state-information"
              target="_blank"
              rel="noopener noreferrer"
            >
              licensing agency in your state
            </a>{" "}
            and inquire about the process. In my state, the process takes a few
            hours of online training and a background check.
          </p>

          <h2>Volunteer for a Staffing</h2>

          <p>
            In the state of Missouri, when you have a license to foster or
            adopt, you can serve as a “community member” at adoption staffing
            events. As you might remember from the earlier section “Inquiring
            About a Prospective Adoptive Child(ren)”, a staffing is a group
            interview with prospective adoptive parents. You get to see what
            types of questions are asked, what families bring to these events,
            and how the group decides which family is the best fit for a
            child(ren).
          </p>

          <h2>Become a CASA (Court Appointed Special Advocate)</h2>

          <p>
            Maybe you’re feeling like committing to a child for their lifetime
            is a lot to ask. A volunteer role worth considering is becoming a
            CASA. CASA&apos;s are volunteers from our community that go through
            background checks, personal and work references, and specialized
            training to advocate for children in foster care. The goal of the
            CASA organization is to have children into a safe, permanent home,
            while also providing the child with a voice in court.
          </p>

          <p>
            Training varies by state and can take around 30 hours. Once trained
            and sworn in by the court you will be assigned as a CASA for a child
            in foster care. Some states request a one year commitment to your
            assigned child and ask you to spend at least one hour a week with
            that child. On average, a CASA might spend 8-10 hours a month
            engaged in their case.
          </p>

          <p>
            Learn more about this role and your state’s process at:{" "}
            <a
              href="https://nationalcasagal.org/advocate-for-children/be-a-casa-gal-volunteer/"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://nationalcasagal.org/advocate-for-children/be-a-casa-gal-volunteer/
            </a>
            .
          </p>
        </article>
      </Container>
    </main>
  );
}
