import { contentTypes } from "../../utility/constants";

export default {
  type: contentTypes.TEXT,
  title: "Docs, Classes, Home Checklist",
  content: (
    <article>
      <p>
        You’re going to need to provide a number of documents, complete various
        classes, and modify your home during the home study process. You can
        turn these requirements in all at once or by the time you finish your
        licensing classes.
      </p>

      <p>I highly recommend making a digital copy of anything you turn in.</p>

      <p>
        <a
          href="https://drive.google.com/file/d/1scBIo_vR25S0RoMVJvIdu-hGyJXSpzdN/view?pli=1"
          target="_blank"
          rel="noopener noreferrer"
        >
          Here’s a printable PDF of the documents, classes and home
          modifications you might need to complete.
        </a>
      </p>

      <p>
        It really wow’d our licensing worker when we had everything except the
        physicals prepared and in a big pile to be turned in when she came for
        the first home visit. But don’t feel like you need to do that. Just know
        you need to turn in a long list of items and stay organized.
      </p>
    </article>
  ),
};
