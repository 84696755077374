import { Routes, Route, Navigate } from "react-router-dom";
import Header from "../components/Header/Header";
import Welcome from "./Welcome";
import Footer from "../components/Footer/Footer";
import About from "./About";
import Preparation from "./Preparation";
import GetFeetWet from "./GetFeetWet";
import FAQ from "./FAQ";
import Testimonials from "../components/Testimonials";
import WhileYouWait from "./WhileYouWait";
import Auth from "./Auth";
import ContentView from "../components/ContentView";
import HomeLayout from "../components/Layouts/HomeLayout";
import useMediaQuery from "../hooks/useMediaQuery";
import Map from "./Map";
import Dashboard from "./Dashboard";
import ChatBot from "./ChatBot";
import QuickLinks from "./QuickLinks";
import PageNotFound from "./PageNotFound";

export default function Home() {
  const isSmUp = useMediaQuery("(min-width: 640px)");
  const isMdUp = useMediaQuery("(min-width: 960px)");

  return (
    <div
      className={`w-full mx-auto block box-border max-w-full h-full ${
        !isMdUp ? "mb-20" : ""
      }`}
    >
      <Header />
      <Routes>
        <Route path="/" element={<HomeLayout />}>
          <Route index={isSmUp} element={<Welcome />} />
        </Route>
        <Route path="steps" element={<HomeLayout />}>
          <Route index={isSmUp} element={<Navigate to="step1Task1" />} />
          <Route path=":taskId" element={<ContentView />} />
        </Route>
        <Route path="/about" element={<About />} />
        <Route path="/preparation" element={<Preparation />} />
        <Route path="/get-your-feet-wet" element={<GetFeetWet />} />
        <Route path="/quick-links" element={<QuickLinks />} />
        <Route path="/faq" element={<FAQ />} />
        <Route path="/testimonials" element={<Testimonials />} />
        <Route path="/while-you-wait" element={<WhileYouWait />} />
        <Route path="/waiting-children" element={<Map />} />
        <Route path="/children" element={<Map />} />
        {process.env.REACT_APP_FLAG_AUTH === "on" && (
          <Route path="/auth" element={<Auth />} />
        )}
        {process.env.REACT_APP_FLAG_AUTH === "on" && (
          <Route path="/dashboard" element={<Dashboard />} />
        )}
        <Route path="/chat" element={<ChatBot />} />
        <Route path="/*" element={<PageNotFound />} />
      </Routes>
      <Footer />
    </div>
  );
}
