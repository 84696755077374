import { contentTypes } from "../../utility/constants";

export default {
  type: contentTypes.TEXT,
  title: "Get Experience",
  content: (
    <article>
      <p>
        <strong>Volunteer for a Staffing</strong>
      </p>
      <p>
        In the state of Missouri, when you have a license to foster or adopt,
        you can serve as a “community member” at adoption staffing events. As
        you might remember from the earlier section “Inquiring About a
        Prospective Adoptive Child(ren)”, a staffing is a group interview with
        prospective adoptive parents. You get to see what types of questions are
        asked, what families bring to these events, and how the group decides
        which family is the best fit for a child(ren).
      </p>
      <p>
        <strong>Research Childcare Providers</strong>
      </p>
      <p>
        My husband and I had initially said we wanted children that were age 4
        and older because we had in our minds that we could afford preschool for
        a 4-year-old. At the time we did not know that (1) childcare expenses
        could be covered by the state and (2) preschools / daycares care for
        children as young as a few months old. Because of our respite
        experience, we learned of a nearby Montessori preschool that accepted
        the state’s payment for childcare. Had we not cared for the boy who was
        going to preschool there, we would not have known this school was an
        option because the online databases available did not have it listed.
      </p>
      <p>
        <strong>
          Talk To Other Families Who Have Adopted Through Foster Care
        </strong>
      </p>
      <p>
        Find ways to stay in contact with other people in your training classes.
        Their experiences can be insightful and encouraging, and they might also
        benefit from learning from you! Being around other families on a foster
        care or foster care adoption journey helps you also become familiar with
        the vocabulary and various normalcies of the process. For instance,
        don’t freak out, you are pretty normal if you get hotlined at some
        point. Hotlining is when someone calls the Child Abuse & Neglect hotline
        to report a child at-risk. We had this happen because of something one
        of our kids did. On my drive to go talk to the police officers involved,
        I called a fellow adoptive mom who walked me through how to handle the
        situation. When a report is made to the Child Abuse & Neglect Hotline, a
        social worker and police officer(s) come and talk with the child about
        what was reported. Based on their findings (<em>read:</em> if abuse or
        neglect is confirmed), the child may or may not stay at their current
        residence or may not be allowed to interact with certain individuals.
      </p>
    </article>
  ),
};
