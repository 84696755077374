import noContent from "./BadRouteContent";

import step1Task1 from "./Step1/step1_task1";
import step1Task2 from "./Step1/step1_task2";
import step1Task3 from "./Step1/step1_task3";
import step1Task4 from "./Step1/step1_task4";
import step1Task5 from "./Step1/step1_task5";

import step2Task1 from "./Step2/step2_task1";
import step2Task2 from "./Step2/step2_task2";
import step2Task3 from "./Step2/step2_task3";
import step2Task4 from "./Step2/step2_task4";
import step2Task5 from "./Step2/step2_task5";
import step2Task6 from "./Step2/step2_task6";

import step3Task1 from "./Step3/step3_task1";
import step3Task2 from "./Step3/step3_task2";
import step3Task3 from "./Step3/step3_task3";
import step3Task4 from "./Step3/step3_task4";
import step3Task5 from "./Step3/step3_task5";
import step3Task6 from "./Step3/step3_task6";

import step4Task1 from "./Step4/step4_task1";
import step4Task2 from "./Step4/step4_task2";
import step4Task3 from "./Step4/step4_task3";

import step5Task1 from "./Step5/step5_task1";
import step5Task2 from "./Step5/step5_task2";
import step5Task3 from "./Step5/step5_task3";

import step6Task1 from "./Step6/step6_task1";
import step6Task2 from "./Step6/step6_task2";
import step6Task3 from "./Step6/step6_task3";
import step6Task4 from "./Step6/step6_task4";
import step6Task5 from "./Step6/step6_task5";
import step6Task6 from "./Step6/step6_task6";

import step7Task1 from "./Step7/step7_task1";
import step7Task2 from "./Step7/step7_task2";
import step7Task3 from "./Step7/step7_task3";

import step8Task1 from "./Step8/step8_task1";
import step8Task2 from "./Step8/step8_task2";
import step8Task3 from "./Step8/step8_task3";
import step8Task4 from "./Step8/step8_task4";
import step8Task5 from "./Step8/step8_task5";

export interface iTaskType {
  type: string;
  title: string;
  content?: JSX.Element;
  playlist?: string;
  resources?: {
    title: string;
    links: {
      link: string;
      platform: string;
    }[];
  }[];
}

export interface iContentType {
  [key: string]: iTaskType;
}

const content: iContentType = {
  noContent,
  step1Task1,
  step1Task2,
  step1Task3,
  step1Task4,
  step1Task5,
  step2Task1,
  step2Task2,
  step2Task3,
  step2Task4,
  step2Task5,
  step2Task6,
  step3Task1,
  step3Task2,
  step3Task3,
  step3Task4,
  step3Task5,
  step3Task6,
  step4Task1,
  step4Task2,
  step4Task3,
  step5Task1,
  step5Task2,
  step5Task3,
  step6Task1,
  step6Task2,
  step6Task3,
  step6Task4,
  step6Task5,
  step6Task6,
  step7Task1,
  step7Task2,
  step7Task3,
  step8Task1,
  step8Task2,
  step8Task3,
  step8Task4,
  step8Task5,
};

export default content;
