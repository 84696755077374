import { contentTypes } from "../../utility/constants";

export default {
  type: contentTypes.PODCAST,
  title: "Worth Listening",
  description: "Related podcasts",
  playlist:
    "https://open.spotify.com/embed/playlist/4YFDoBW3tQXCDwYR3D5r0t?si=296f3ae3af6046f4https://open.spotify.com/embed/playlist/2FeDGu1CdcZWXFxtr3kCZO?utm_source=generator",
  resources: [
    {
      title: "HOW to know you're ready to adopt",
      links: [
        {
          link: "https://podcasts.apple.com/us/podcast/how-to-know-youre-ready-to-adopt/id1542072701?i=1000550464867",
          platform: "apple",
        },
        {
          link: "https://spotifyanchor-web.app.link/e/5IwtrBFOstb",
          platform: "spotify",
        },
      ],
    },
    {
      title:
        "HOW you can obtain required training hours without having to go in-person to a class",
      links: [
        {
          link: "https://podcasts.apple.com/us/podcast/how-you-can-obtain-required-training-hours-without/id1542072701?i=1000553294243",
          platform: "apple",
        },
        {
          link: "https://spotifyanchor-web.app.link/e/Kg2tFAFOstb",
          platform: "spotify",
        },
      ],
    },
  ],
};
