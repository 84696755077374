import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import YouTubeIcon from "@mui/icons-material/YouTube";
import { FooterListButton } from "../../components/UIComponents/Buttons";
import { FooterListTitle } from "../../components/UIComponents/Typography";
import useMediaQuery from "../../hooks/useMediaQuery";

export default function FooterBookList() {
  const isDesktop = useMediaQuery("(min-width: 960px)");
  return (
    <ul className="prose dark:prose-invert max-w-none prose-reset-list prose-hr:m-0 prose-link prose-a:font-normal w-full lg:w-64 m-0 p-0">
      <FooterListTitle className={isDesktop ? "" : "hidden"}>
        Books
      </FooterListTitle>

      <FooterListButton href="https://www.amazon.com/What-Know-Adopt-Through-Foster-ebook/dp/B0CKZJWMMD?ref_=ast_author_mpb">
        <AutoStoriesIcon />
        What to Know When You Adopt Through Foster Care
      </FooterListButton>

      <FooterListButton href="https://www.amazon.com/Are-Forever-Family-Marcy-Bursac-ebook/dp/B09Y67NHSF">
        <AutoStoriesIcon />
        Are You a Forever Family?
      </FooterListButton>

      <FooterListButton href="https://www.youtube.com/watch?v=ki_gxOGwUvw">
        <YouTubeIcon />
        Storytime Pup reads: Are You a Forever Family?
      </FooterListButton>

      <FooterListButton href="https://www.amazon.com/gp/product/B08MQVKB1Q">
        <AutoStoriesIcon />
        The Forgotten Adoption Option
      </FooterListButton>
    </ul>
  );
}
