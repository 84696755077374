export default {
  title: "",
  content: [
    "You can get licensed for free through a state or private agency. State options sound like “Department of Children Services or something similar (different states have different acronyms). Private options have a variety of names. In my area, private options are places like Bringing Families Together, One Heart Family Ministries, Lutheran Family & Childrens Services, and “Foster & Adoptive Care Coalition” to name just a few. Private agencies receive funding from the state to help train families and care for child(ren). Caring for children is called “managing cases,” so social workers manage a certain number of cases”or children. You might want to pursue the agency (a) with training on a schedule that fits yours, (b) that a friend or family member recommends, or (c) that aligns with your needs.",
    "Picking an agency to go through can include several choices or closed doors. Beware that if you want to pursue an adopt-only license (again this is known as foster-to-adopt in some areas), some agencies only give this license to a set number of families at a time. Therefore, you might contact an agency and hear they are not offering that license or they “don’t do” that kind of license. Hang up the phone or close the email and keep searching. You’ll eventually find an agency that can help you if you keep searching. Similarly, if you identify on the LGBTQIA+ spectrum, you might also hear some agencies say they do not license same-sex couples. Again, keep searching because there are LGBTQIA+-friendly agencies out there.",
    "One of the best places to start:",
    "at AdoptUSKids.org. Here you can even browse profiles for waiting children without any commitment",
    "by visiting AdoptUSKids on Facebook",
    "by calling AdoptUSKids at 888-200-4005",
    "by emailing info@adoptuskids.org",
    "AdoptUSKids is ready to answer questions and connect you with an agency in your area.",
  ],
};
