export default {
  title: "Name Your Fears",
  content: [
    "Philophobia - The Fear of Becoming Emotionally Connected to Another Person",
    "Big Brother experienced physical harm and verbal abuse. As a result, when we first started living together, he would buck anything my husband would do, say, or try to interject. A few months in, my husband had a lightbulb moment! Since Big Brother had only known adult males to be mean, violent and harmful to him, he must think that my husband was going to be mean, violent and harmful to him.",
    "One day in the car I had had enough of Big Brother&apos;s bad attitude towards my husband, and we stopped the car and talked about it. I explained to Big Brother that my husband is a kind man who would never lay a hand on him out of anger. It took near nightly repeats of the same reminder. My husband would use positive language toward Big Brother You are a kind young man, who I love and would never harm. and Big Brother would use positive words with my husband You are a kind man who is patient and caring. It took awhile but we eventually saw huge progress.",
    "According to ChildWelfare.gov, 64% of children who are in foster care stay in foster homes for anywhere between one and 24 months. Some children stay in one home during the time; others move around for various reasons. Regardless of the number of times the child has relocated, being in foster care can make a child question if adults are worth trusting or if adults even care about them.",
    "Gerascophobia - The Fear of Being Too Old",
    "Bryan is an adoptive parent in Florida who grew up with a friend of mine. He is in the process of his second adoption of a 12-year-old boy. His first adoption was a 13-year-old boy who felt abandoned and like he was too old for anyone to want him. Maybe these doubts were because he had a sense of what happens to older kids who go into foster care.",
    "Foster care is intended to care for children ages birth to age 18. However, according to the Dave Thomas Foundation for Adoption, 45% of children waiting to be adopted are age 8 or older.",
    "Teenages, especially, are afraid they will reach their 18th birthday without being adopted. Their fear is a fear because it could be a reality. According to the National Foster Youth Institute, over 23,000 children do not get adopted by the time they are 18 or when they graduate from high school; therefore, they enter adult life without an adoptive family.",
    "Macrophobia - The Fear of Having to Wait a Long Time",
    "A foster mom friend of mine took care of a sibling pair in foster care for two years, two months, and two days. When they were searching for their forever family the children were ages 7 and 8. They found forever home, but then the soon-to-be-adoptive mom got diagnosed with cancer. Since the adoption had not been legally finished (this occurs after the children live with their pre-adoptive home for six months),the children were sent back to live with my foster mom friend and her family. It took another 9-10 months to find their adoptive home. Can you imagine the disappointment and disbelief these children faced? They had just thought they were going to have a family, and that was taken away from them. The children were already well aware of having to wait over two years to find a family.",
    "Waiting to be adopted can be a long process for the child. Many movies like Disney s Meet the Robinsons and Illumination Entertainment Despicable Me portrays the excitement mixed with disappointment children feel when waiting for a forever family. I wish there was a magic wand I could wave so children did not have to go through the mental and emotional ups and downs that occur when waiting.",
    "Sciophobia - The Fear of Shadows",
    "I got permission from the State of Missouri to bring Big Brother and Little Sister with me to Cleveland, Ohio for my sister s doctoral graduation. We stayed with one of my college friends who lives in Columbus, Ohio so we could spend quality time in two cities during the trip. When I put Big Brother to bed on the first night in my friend s home, it was a different room and bed than he was used to. In fact, it was dark, and Big Brother was used to a night light. After doing bedtime prayers and tucking him in, I went to close the door and Big Brother stopped me explaining he was scared. I stopped and sat on the bed asking him to describe what made him scared. Tucked back in his memory was something his biological mother had told him - that there was a Boogie Man in the closet. He was afraid the Boogie Man was going to come out in the dark and get him. So I sat there and asked Big Brother if anything has ever come out of a closet and gotten him. No. And if monsters are real. No. I could see the wheels turning in his head as he blurted out,",
    "“Wait. Do you mean the Boogie Man is a lie?” The Boogie Man turned out to be a way he was controlled to stay in his bed. He had been told if he got out of bed in the dark the Boogie Man would get him.",
    "Children have a backstory. One they might have words for or one they might not even be aware of. Whatever their traumas and hurts, they might be afraid that they are unlovable because of what happened, what they saw, what they know, or who they are.",
    "Atelophobia - The Fear of Things Going Wrong",
    "Call it going through the stages of grieving or the trauma experienced while being in foster care, at bedtime Big Brother and Little Sister would often ask when they would be going back to their biological family. Okay, their words were really, “When are we going home?” We knew that was not the goal, but it was not our place to tell Big Brother and Little Sister that. It was not official, and we should not be the ones to give that news. Big Brother often worried that he was not going to go back. And to him, not going back meant something was wrong. He worried about it a lot and talked about it a lot. To him, he wanted to fix the situation and make everyone better.",
    "Children can feel like being in foster care is their fault and maybe - just maybe - if they act a certain way or pray hard enough they will fix the situation.",
    "Sociophobia - The Fear of Embarrassment in Social Situations",
    "Little Sister spoke in single words when we first met. In fact, Big Brother would help translate for her when my husband and I did not understand what she meant. One night I was putting her to bed after a biological family visit, and she leaned in to kiss me directly on my lips. My intuition was very strong, and I quickly turned my face before she kissed my lips. I ended up with a wet tongue kiss on my cheek. When I looked back at her, she was smiling proudly. “Oh, what was that?” I asked. “A Mickey Mouse kiss,” she said. Internally I was asking myself what that was, and I was also slightly freaking out that Little Sister had just nearly french kissed me. Trying to remain calm, I asked, “Oh, where did you learn that?” She told me it was something her biological mom taught her during the visit earlier in the day. Knowing this was not an appropriate thing for a child but also not wanting to make Little Sister feel awkward, I replied, “Oh, well Mommy Marcy does not want those kinds of kisses, please.” I finished tucking Little Sister into bed and reported the behavior to the Child Abuse Hotline. (We will talk more about Hotlining in Part Three.)",
    "A child’s back story can be filled with raw emotions and uncertainty, and they might need professional help to process, cope, and heal fromtheir past. Some of the things out of their mouths might be complicated.",
    "Complicated because they do not realize how anyone else will react to what they say. Complicated because they might not know if they can share details of what they remember out of fear of how you will react. Complicated because they might not even know the words for what they experienced or are feeling.",
    "Adult Fears",
    "Chrometophobia - The Fear of Money",
    "Some believe that “adoption” and “expensive” go hand in hand. I used to believe this, too, until I dug deeper and discovered these two words do not always go together. There is a bit of truth to this if you pursue a domestic infant adoption or international adoption. Foster care adoption, in comparison, costs a few thousand dollars.",
    "Type Cost Length of Process",
    "Domestic Infant Adoption $34,000+ 2-7 years",
    "International Adoption $30,000+ 1-5 years Foster Care Adoption $0 - $2,500 6-18 months",
    "Sources: American Adoptions, Adopt.org, ChildWelfare.gov,AdoptUSKids.org, DaveThomasFoundation.org",
    "Without doing a single fundraiser or asking friends and family for money, the legal costs of adopting my two children (at the same time) was financially manageable. And thanks to federal adoption tax credits,we got it all back and then some.",
    "The Dave Thomas Foundation for Adoption states that foster care adoption can cost $0 - $2,500.<sup>[5]</sup> My sibling pair adoption fell within that cost range.",
    "Here’s a look at things we paid for:",
    "Family Book - $25 per book x 8 books = $150",
    "Adoption Lawyer Legal Fee - $100",
    "Adding safety covers to outlets throughout my home - $5",
    "Adding child safety locks to cabinets throughout my home - $20",
    "Purchasing a fire extinguisher for each level of my home - $30 x 3 = $90",
    "Total: $365",
    "I live in a home that was built as a multi-family unit in the 1930’s, so we knew if we were going to make the two children’s bedrooms have matching captain’s beds we would need to hire a carpenter to replicate the one captain’s bed that the house already had.",
    "Carpenter - $2,000",
    "Federal Tax Credits",
    "At the time of writing this book, the federal adoption tax credit is $14,300 per child for children adopted through foster care. If you do the math, you can quickly see that my expenses were very small in comparison to the financial support provided by the federal government. You can use this credit however you please; be it setting up a college savings account, for expenses of raising the child, whatever you want.",
    "Raising a child or children definitely means a host of costs for things from school supplies and clothing to food and medical expenses. This section only covers the legal fees associated with finalizing an adoption through foster care.",
    "Philophobia - The Fear of Becoming Emotionally Connected to Another Person",
    "This is a very real fear. When you bring children into your home, you have no idea what kind of dynamic you are getting into. But think about your significant other or a previous relationship: did you really know all the ins and outs to that person before you decided to date them?Probably not. You learn quirks and habits over time.",
    "You are going to have doubts. You are even going to question adopting a child. And you might even question your parenting skills at times",
    "When I experienced a breakup with a guy I dated in college, I was crushed and heartbroken. My dad reminded me of Alfred Lord Tennyson’s words It is better to have loved and lost than to never have loved at all. Maybe you have experienced those feelings, too. I can share from the inside the journey of living together and then adopting my children that there were moments when I questioned if I wanted to put my heart out there without a promise of these two little humans being in my family forever. I remember staying up late talking to my husband about how I was not sure my heart could handle our Big Brother and Little Sister going back to their biological family and out of our lives.",
    "However, it was in that moment that I realized I would either hold back the depth of my love and let them experience nice people who were around, or I could risk it all and know that no matter what tomorrow held, I had given Big Brother and Little Sister a gift - the gift of knowing what it was like to be loved as someone’s child. And so with a keen awareness of there being no promise on the other side, it was in that moment that I knowingly committed to loving Big Brother and Little Sister deeply as long as I was raising them no matter the heartbreak that might be on the other side.",
    "Gerascophobia - The Fear of Being Too Old",
    "If it were up to me, I would be frozen at age 25 because then no one older than me would need to age. Maybe you have a number you wish you could freeze your age. Just like there is no age limit for becoming a parent or raising children, there is not an age limit on adopting children through foster care. As my children entered preschool and elementary school I quickly realized this is so true. There is a wide age span of people who are parents. According to the Dave Thomas Foundation for Adoption, nearly 25% of adopted children have a parent that is over age 50.",
    "Your life experiences up until this point can be both a help and/or an excuse for pursuing adoption. Having been through a loss or a time when you had a go into survival mode might give you special skills to be able to relate to child(ren) who you might care for. On the contrary, if you have gone through some rough times or pain that you have not dealt with, scheduling time with a professional counselor might be something you need to do to get yourself to an emotionally healthy place to be a parent.",
    "Macrophobia - The Fear of Having to Wait a Long Time",
    "The process of adopting a child has a connotation of being a drawn out rollercoaster. You might question if you have the stomach to go through the ups and downs. You might also feel like not having a guarantee or not knowing the month or day you will be able to legally adopt a child sounds anything but attractive. According to the National Adoption Foundation, the fastest way to adopt a child is to go through the foster care system. Their research demonstrates that once beginning the process, a foster care adoption can be completed on average in 6-18 months.",
    "Sciophobia - The Fear of Shadows",
    "Baggage. We all have it. Emotional baggage is part of life be it from our childhood peers, the family we grew up in, experiences we have had, or all of the above and then some. One of the healthiest but hardest parts for me in preparing to adopt our children was to really face my past. The initial home study questionnaire (more on this in Part Three showed me some areas in my life where I still had some kinks. The kinks lingered in my mind for days. I knew I should not let them stay there, so I asked some close friends who value and encourage professional counseling and began seeing a counselor weekly. We met for an agreed-upon number of weeks and talked about the things that I had not processed from my childhood. Sometimes I left with homework of conversations to have with certain people, and sometimes I left with a model for how to work on digesting my kinks. It was time well spent, but it also required a good level of emotional and mental energy to start and keep going to counseling.",
    "Atelophobia - The Fear of Things Going Wrong",
    "I remember talking to my husband about my own atelophobia countless times. To me, it was so unpredictable to read a short blurb about a child and know that s/he was going to blend well with us, make good choices, and not harm her/himself or others. Lots of “what ifs” peppered my thoughts. My husband was able to stay level-headed with me through my questions and reminded me even if we had a biological child none of these questions would be answered with promises or certainty.",
    "Hippopotomonstrosesquippedaliophobia - The Fear of Complicated Words",
    "If you can pronounce that, you now know one of the longest words in the dictionary! You might be overwhelmed by the thought of expanding your vocabulary and having to learn a host of acronyms. Foster care adoption is riddled with what can feel like a pocketbook of new words. There are acronyms for various people who are involved with your kids (like DJO or CASA - more about these in Part Three), diagnoses the child may have,forms you will need to fill out on the regular until the adoption is complete, and terms you might text or email your social worker. Just like anything else you have done in your life, in time new words feel familiar, and you get into a groove.",
  ],
};
