import { contentTypes } from "../../utility/constants";

export default {
  type: contentTypes.PODCAST,
  title: "Worth Listening",
  playlist:
    "https://open.spotify.com/embed/playlist/7CivonDjZjkCoyXWfoINu0?si=2bfc531b68bc4ff6",
  resources: [
    {
      title:
        "HOW to make a make a child in foster care comfortable in your home",
      links: [
        {
          link: "https://podcasts.apple.com/us/podcast/how-to-make-a-make-a-child-in-foster-care/id1542072701?i=1000581803580",
          platform: "apple",
        },
        {
          link: "https://spotifyanchor-web.app.link/e/KXk7SpYfnub",
          platform: "spotify",
        },
      ],
    },
  ],
};
