export default {
  title:
    "Ask Yourself (and your significant other if applicable) these questions",
  content: [
    "Rita Soronen, President & CEO of Dave Thomas Foundation for Adoption, encourages people considering foster care adoption to ask themselves:",
    "Am I willing to learn about the dynamics of abuse and neglect and understand the kind of trauma a child experiences before moving into foster care, and often, after? Am I willing to commit to a child permanently?",
    "What kind of supportive networks do I have or am I willing to find?",
    "You might find yourself also wondering: What kind of home is “ready” to adopt?",
    "What would “ready” really look like?",
  ],
};
