import { contentTypes } from "../../utility/constants";

export default {
  type: contentTypes.TEXT,
  title: "The Legal Stuff",
  content: (
    <article>
      <p>
        When finalizing the adoption of a child, children, or sibling group,
        there are a few legal steps you should consider:
      </p>

      <ul>
        <li>
          <strong>Social Security Cards -</strong> At minimum, you’ll need to
          update the child’s legal name on their social security card. We were
          advised to get our children entire new social security numbers to
          avoid the biological family reusing the existing numbers to qualify
          for other benefits.
        </li>
        <li>
          <strong>Birth Certificates -</strong> You’ll need to go through the
          process of getting your child(ren)’s new legal names to be on their
          birth certificate. This will also include adding your (the adoptive
          parent(s)’ names to the birth certificate as well.
        </li>
        <li>
          <strong>Wills -</strong> If you have an existing will, you’ll want to
          consider adding your adopted child(ren) to it. If you don’t have a
          will yet, consider getting one so that you can list out who would
          raise your children in the event of your death. You can make an
          affordable will rather quickly at www.mamabearlegalforms.com.
        </li>
        <li>
          <strong>Bank Accounts -</strong> Once you have your child’s social
          security card/number updated, you can open a youth savings and/or teen
          savings and checking account to help create the habit of savings and
          to teach good money habits.
        </li>
        <li>
          <strong>College Savings Accounts -</strong> As you continue to receive
          a monthly subsidy through age 18 as you raise your adopted child(ren),
          consider putting some of the revenue into a savings account like a ESA
          (educational savings account) or MOST 529 college savings plan.
        </li>
      </ul>
    </article>
  ),
};
