import { useCallback, useMemo, useRef, useState, useLayoutEffect } from "react";
import * as d3 from "d3";
import { FeatureCollection, iData } from "../types/d3mapTypes";
import { iMapData } from "../types/dataStoreTypes";

interface iD3Map {
  width: number;
  height: number;
  geoJsonPath: string;
  numberOfBuckets?: number;
  data?: Array<iData>;
  legendLabel?: string;
  clickHandler: (featureName: string) => void;
  resetHandler: () => void;
  mapData: iMapData[];
}

export default function D3Map({
  width,
  height,
  geoJsonPath,
  numberOfBuckets = 5,
  data,
  legendLabel = "",
  clickHandler,
  resetHandler,
  mapData,
}: iD3Map) {
  const mapSVGRef = useRef(null);
  const [geoJson, setGeoJson] = useState<FeatureCollection>();

  useLayoutEffect(() => {
    d3.json<FeatureCollection>(geoJsonPath).then(json => {
      let features = json?.features;
      features = features?.map(feature => ({
        ...feature,
        css: {
          opacity: 1,
          strokeWidth: 0.5,
          stroke: "black",
        },
      }));
      const updatedJson = {
        type: json && json.type,
        features,
      };
      setGeoJson(updatedJson as FeatureCollection);
    });
  }, [geoJsonPath, setGeoJson]);

  const projections = useMemo(
    () =>
      geoJson
        ? d3
            .geoAlbersUsa()
            .scale(1)
            .translate([width / 2, height / 2])
            .fitSize([width - 40, height - 80], geoJson)
        : null,
    [width, height, geoJson]
  );

  const pathGenerator = useMemo(
    () => d3.geoPath().projection(projections),
    [projections]
  );

  const colorScheme = useMemo(
    () => d3.schemeBlues[numberOfBuckets],
    [numberOfBuckets]
  );

  const colorScale = useMemo(() => {
    const thresholdScale = d3.scaleThreshold();
    if (data) {
      thresholdScale.domain([500, 1000, 2000, 3000, 4000]);
      thresholdScale.range(colorScheme as Iterable<number>);
      return thresholdScale;
    }
    return thresholdScale;
  }, [data, numberOfBuckets]);

  const fillColor = useCallback(
    mapFeature => {
      const featurePathName = mapFeature.properties.STATE_NAME;
      const value = data?.find(
        d => d.name.toLowerCase() === String(featurePathName).toLowerCase()
      )?.value;
      if (value) {
        return colorScale(value);
      }
      return "#ccc";
    },
    [data]
  );

  const handleStateClick = useCallback(
    (event, stateFeature) => {
      event.stopPropagation();
      let features = geoJson?.features;
      features = features?.map(feature => ({
        ...feature,
        css: {
          opacity: 0.5,
          strokeWidth: 0.5,
          stroke: "black",
        },
      }));

      features = features?.filter(feature => {
        if (
          feature.properties.STATE_NAME === stateFeature.properties.STATE_NAME
        )
          return false;

        return true;
      });

      features?.push({
        ...stateFeature,
        css: {
          opacity: 1,
          strokeWidth: 2,
          stroke: "white",
        },
      });

      setGeoJson(
        json =>
          ({
            ...json,
            features,
          } as FeatureCollection)
      );
      const featureName = stateFeature.properties.STATE_NAME;
      clickHandler(String(featureName));
    },
    [geoJson, setGeoJson, mapData]
  );

  const resetStateSelection = useCallback(() => {
    let features = geoJson?.features;
    features = features?.map(feature => ({
      ...feature,
      css: {
        opacity: 1,
        strokeWidth: 0.5,
        stroke: "black",
      },
    }));
    setGeoJson(
      json =>
        ({
          ...json,
          features,
        } as FeatureCollection)
    );
    resetHandler();
  }, [geoJson, setGeoJson]);

  return (
    <svg
      ref={mapSVGRef}
      className="Map transition-all duration-200 min-w-[300px]"
      filter="url(#dropShadow)"
      cursor="default"
      onClick={resetStateSelection}
      viewBox={`0 0 ${width} ${height}`}
    >
      {geoJson ? (
        <>
          <filter id="dropShadow">
            <feDropShadow
              dx="0"
              dy="0"
              stdDeviation="4"
              floodColor="rgba(0, 0, 0, 0.5)"
            />
          </filter>
          <g className="statesGroup" transform="translate(20, 0)">
            {geoJson?.features?.map(stateFeature => (
              <path
                key={`state_${stateFeature.properties.STATE_NAME}`}
                d={pathGenerator(stateFeature) as string}
                className={`state ${stateFeature.properties.STATE_NAME} transition-all duration-200`}
                cursor="pointer"
                stroke={stateFeature.css.stroke as string}
                strokeWidth={stateFeature.css.strokeWidth as string}
                opacity={stateFeature.css.opacity as string}
                strokeLinejoin="round"
                fill={fillColor(stateFeature) as string}
                onClick={event => handleStateClick(event, stateFeature)}
              >
                <animate
                  attributeName="fill"
                  from="#ccc"
                  to={fillColor(stateFeature) as string}
                  dur="0.1s"
                  repeatCount="1"
                />
              </path>
            ))}
          </g>
          <text
            className="fill-slate-500 dark:fill-slate-100"
            x="0"
            y="0"
            textAnchor="middle"
            fontSize="12"
            fontStyle="italic"
            transform={`translate(${width / 2}, ${height - 60})`}
          >
            Click on the map to interact and click outside to reset.
          </text>
          <g className="legendGroup">
            <text
              className="fill-black dark:fill-white"
              x="0"
              y="0"
              fontSize="9"
              transform={`translate(${
                width / 2 - (numberOfBuckets / 2) * (200 / numberOfBuckets)
              }, ${height - 40})`}
            >
              {legendLabel}
            </text>
            {Array.from({ length: numberOfBuckets }, (_, index) => (
              <g className="legendRect" key={`legendRect_${index}`}>
                <rect
                  x={index * (200 / numberOfBuckets)}
                  y="5"
                  width={200 / numberOfBuckets}
                  height={20}
                  transform={`translate(${
                    width / 2 - (numberOfBuckets / 2) * (200 / numberOfBuckets)
                  }, ${height - 40})`}
                  fill={colorScheme[index]}
                />
                <text
                  className="fill-black dark:fill-white"
                  x={index * (200 / numberOfBuckets) + 200 / numberOfBuckets}
                  textAnchor="end"
                  y={40}
                  fontSize={9}
                  transform={`translate(${
                    width / 2 - (numberOfBuckets / 2) * (200 / numberOfBuckets)
                  }, ${height - 40})`}
                >{`${colorScale.domain()[index]}`}</text>
              </g>
            ))}
          </g>
        </>
      ) : (
        <g transform={`translate(${width / 2}, ${height / 2})`}>
          <circle className="fill-primary" stroke="none" cx="-20" cy="0" r="6">
            <animate
              attributeName="opacity"
              dur="1s"
              values="0;1;0"
              repeatCount="indefinite"
              begin="0.1"
            />
          </circle>
          <circle className="fill-primary" stroke="none" cx="0" cy="0" r="6">
            <animate
              attributeName="opacity"
              dur="1s"
              values="0;1;0"
              repeatCount="indefinite"
              begin="0.2"
            />
          </circle>
          <circle className="fill-primary" stroke="none" cx="20" cy="0" r="6">
            <animate
              attributeName="opacity"
              dur="1s"
              values="0;1;0"
              repeatCount="indefinite"
              begin="0.3"
            />
          </circle>
        </g>
      )}
    </svg>
  );
}

D3Map.defaultProps = {
  data: [],
  legendLabel: "",
  numberOfBuckets: 5,
};
