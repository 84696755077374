import { useCallback, useLayoutEffect, useState } from "react";

interface Size {
  width: number;
  height: number;
}

export default function useElementSize<
  T extends HTMLElement = HTMLDivElement
>(): [(node: T | null) => void, Size] {
  const [ref, setRef] = useState<T | null>(null);
  const [size, setSize] = useState<Size>({
    width: 0,
    height: 0,
  });

  // Prevent too many rendering using useCallback
  const handleSize = useCallback(() => {
    const newWidth =
      ref == null || ref.offsetWidth == null || ref.offsetWidth <= 0
        ? 300
        : ref.offsetWidth;
    const newHeight =
      ref == null || ref.offsetHeight == null || ref.offsetHeight <= 0
        ? 180
        : ref.offsetHeight;
    setSize({
      width: newWidth,
      height: newHeight,
    });
  }, [ref]);

  useLayoutEffect(() => {
    handleSize();
    window.addEventListener("resize", handleSize);
    return () => {
      window.removeEventListener("resize", handleSize);
    };
  }, [ref]);

  return [setRef, size];
}
