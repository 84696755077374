import { MoonIcon, SunIcon } from "@radix-ui/react-icons";
import useDarkMode from "../../hooks/useDarkMode";

export default function DarkModeButton() {
  const [isDark, setIsDark] = useDarkMode();

  function toggleTheme() {
    setIsDark(!isDark);
  }

  return (
    <button type="button" onClick={toggleTheme}>
      {isDark ? (
        <SunIcon className="w-6 h-6 text-primary" />
      ) : (
        <MoonIcon className="w-6 h-6 text-primary" />
      )}
    </button>
  );
}
