import { Link } from "react-router-dom";
import useMediaQuery from "../../hooks/useMediaQuery";

export default function Logo() {
  const isSmallDown = useMediaQuery("(max-width: 600px)");

  if (isSmallDown) {
    return (
      <Link to="/" className="font-logo text-center uppercase text-primary">
        <p className="tracking-widest">
          <span className="inline-block w-[77px] mb-[5px] border-b border-primary/50" />
          <span className="mx-4">The</span>
          <span className="inline-block w-[77px] mb-[5px] border-b border-primary/50" />
        </p>
        <p className="font-bold text-xl tracking-widest">
          Forgotten Adoption Option
        </p>
        <p className="tracking-widest">
          <span className="inline-block w-[77px] mb-[5px] border-b border-primary/50" />
          <span className="mx-4">App</span>
          <span className="inline-block w-[77px] mb-[5px] border-b border-primary/50" />
        </p>
      </Link>
    );
  }

  return (
    <Link
      to="/"
      className="grow-1 shrink-0 flex items-center my-2 border-t border-b border-primary/50 font-logo font-bold text-4xl uppercase tracking-widest text-primary"
    >
      The Forgotten Adoption Option App
    </Link>
  );
}
