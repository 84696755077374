export default [
  {
    img: "./images/testimonial1.jpg",
    alt: "Amber and her family",
    attribution: "- Amber, adoptive mom of a sibling pair",
    podcastLink: "https://spotifyanchor-web.app.link/e/m4YNthtcbvb",
    content: `As a former leader of an adoption ministry, I have given away over 10
    copies of this book to people interested in learning more about
    adoption from foster care. You will not find any other books that
    provide an overview of the foster care system and practical advice for
    first-time foster/adoptive parents. It corrects misconceptions, offers
    high-level and detailed information, and ultimately removes barriers
    to action for the good of children in need.`,
  },
  {
    img: "./images/testimonial2.jpg",
    alt: "Emily and her husband Ben",
    attribution: "- Emily, future adoptive mom",
    podcastLink: "https://spotifyanchor-web.app.link/e/Vv42n8ucbvb",
    content: `The Forgotten Adoption Option is our go-to resource as we pursue
    adoption. Anytime we have a question or wonder, "is this
    normal?", we refer to it to make sure we are on the right track.
    It offers advice, how-tos and encouragement for the journey ahead!`,
  },
];
