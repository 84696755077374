import { contentTypes } from "../../utility/constants";

export default {
  type: contentTypes.TEXT,
  title: "The Surprises",
  content: (
    <article>
      <p>
        <strong>Contact with Bio Family</strong>
      </p>
      <p>
        Every state has their own adoption laws. Missouri is a closed adoption
        state. This means it is up to the adoptive parents if they want to
        maintain contact with the biological family. Given the instability of
        the biological parents, we have chosen to not have contact. We wanted to
        give Big Brother and Little Sister the gift of moving on, growing in
        their gifts, and having a stable life.
      </p>
      <p>
        Later in life, like when Big Brother and Little Sister are in high
        school, we are going to explore contacting their biological family if
        this is something Big Brother and Little Sister want to do.
      </p>

      <p>
        <strong>Processing Their Story</strong>
      </p>
      <p>
        At peppered moments, Big Brother has wondered why he could not remain
        with his biological family. Little Sister has curiosities about her
        childhood and the people she is related to. We try to be as honest as
        possible answering their questions while “not putting memories where
        memories don’t exist” (another piece of advice from the play therapist).
      </p>

      <p>
        <strong>Last Name, First Name</strong>
      </p>
      <p>
        Since I was a young girl, I had a name picked out for my future
        daughter. But, when we were talking with our play therapist about the
        names we had picked out for Big Brother and Little Sister when we
        legally became a forever family, she cautioned that our children were
        old enough to remember their first names and that perhaps changing their
        middle and last names would be a better idea. So that’s what we did. We
        gave Little Sister the name I picked out for my future daughter:
        “Markie” which means “pearl”. We gave Big Brother the name of my
        husband’s grandfather “Sam” who inspired my husband’s desire to adopt.
        Sam means “God has heard.” Both names feel well-suited.
      </p>

      <p>
        <strong>If My Husband and I Died</strong>
      </p>
      <p>
        There is a really weird gray area before the adoption court hearing
        after you have decided to be a forever family. If both prospective
        adoptive parents die, the children who they intend to adopt go back into
        the foster care system. My heart broke knowing this reality. I remember
        praying when I was in the car that I would be safe and at the very least
        be able to adopt my children. The unpromised future wasn’t enough for
        me. So, we talked with our children’s social worker and learned we could
        pick out which foster family our children could go to if my husband and
        I died. These were friends and not family but declaring these two
        couples gave us peace of mind.
      </p>

      <p>
        <strong>Vacations</strong>
      </p>
      <p>
        It is a good idea to allow time for your child(ren) to adjust to your
        home and the schedule and routines you would like to create. Early in
        our training we were told a story about a family that had adopted
        children and then shortly after they took the children on a cruise. The
        children were not able to relax and enjoy the vacation because they had
        never taken a vacation before. Then when they returned to their
        permanent home they were confused and acted out because they had moved
        to two different places in a short time which reminded them of the
        constant moves of their life in foster care. My children moved in in May
        and we had a long-hoped-for vacation planned for July. Without a doubt,
        we knew we needed to cancel our vacation. We contacted the owner of the
        rental property and he was so happy for us and quickly agreed to refund
        our money. A year later, we took our first family vacation to a lake
        home in Wisconsin.
      </p>

      <p>
        <strong>Social Media</strong>
      </p>
      <p>
        Social media is a dynamic animal. If you look for positives, you can
        find them. If you look for negatives, you can find them. We changed our
        Facebook settings and blocked certain people who are related to our
        children. There are some, but few, photos of my children on social
        media. These were choices we made to allow their faces to be circulated
        with intentionality. You’ll need to decide what is the right approach
        for your family.
      </p>

      <p>
        <strong>Secrets Versus Surprises</strong>
      </p>
      <p>
        We use the word “surprises” when we are preparing for one child’s
        birthday and let the other child know what the gifts are. Since our
        children have been exposed to keeping secrets, we don’t keep secrets. We
        keep surprises, but as the old saying goes, “Secrets, secrets are not
        fun. Secrets, secrets hurt someone.” You might need to modify your
        language and behavior in the best interest of your children.
      </p>
    </article>
  ),
};
