import { contentTypes } from "../../utility/constants";

export default {
  type: contentTypes.PODCAST,
  title: "Worth Listening",
  playlist:
    "https://open.spotify.com/embed/playlist/1xUlcy02Idh6mXbviYZ083?si=5b5ccf123e75492a",
  resources: [
    {
      title: "HOW to find and become an adoption-friendly workplace",
      links: [
        {
          link: "https://podcasts.apple.com/us/podcast/how-to-find-and-become-an-adoption-friendly-workplace/id1542072701?i=1000559321789",
          platform: "apple",
        },
        {
          link: "https://spotifyanchor-web.app.link/e/8GF2jzFOstb",
          platform: "spotify",
        },
      ],
    },
  ],
};
