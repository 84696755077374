import { Container } from "../components/UIComponents/Container";
import Breadcrumbs from "../components/UIComponents/Breadcrumbs";
import { ArticleHeader } from "../components/UIComponents/Typography";

export default function FAQ() {
  return (
    <main>
      <Container className="flex flex-col flex-nowrap justify-center max-w-[1200px] w-[95%] md:w-[80%]">
        <article className="relative">
          <Breadcrumbs separator="›" crumbs={["Home", "Get Started", "FAQ"]} />
          <ArticleHeader>FAQ</ArticleHeader>

          <h2>What is foster care adoption exactly?</h2>
          <p>
            While foster care exists as a temporary place for children to be
            safe, not all children in the system go back to their biological
            families. People, like me, adopted directly through foster care and
            there are still thousands of children waiting for more people to do
            the same for them.
          </p>

          <h2>Who are waiting children?</h2>
          <p>
            Most often you’ve heard “waiting children” called “foster children”
            because they are currently in foster care. However, waiting children
            are children who will not be returning to their biological families.
            In fact, waiting children wait in foster care until they are adopted
            or they age out of the foster care system.
          </p>

          <h2>What can I do if my spouse is not on board?</h2>
          <p>
            I would first invite you to better understand why your spouse is not
            on board. Often, the reservation is rooted in something. Be sure to
            have this conversation when you’re ready to be an active and
            empathetic listener. Sometimes the root cause is fear of being a
            good parent, fear of giving up personal time, or other vulnerable
            feelings.
          </p>

          <h2>How much does this cost?</h2>
          <p>
            Financially, you can expect to spend $0-$2,500 out of pocket.
            Currently, you’ll receive a federal tax credit of more than $14,000
            for each child you adopt through foster care. Until your adopted
            children are age 18, their health insurance is covered by the state
            as well.
          </p>

          <h2>Can I adopt a baby through the foster care system?</h2>
          <p>
            While not very likely, sometimes yes. According to KidsCount, 40% of
            waiting children are ages 1-5 and over 50% are ages 6-15.
          </p>

          <h2>How do I prepare my biological children?</h2>
          <p>
            Many adoptive families find it healthy to include their biological
            children from the very beginning. Talking about the topic together
            and allowing space for questions and concerns is a great way to
            support child(ren) who will be joining your family. Consider reading
            my children’s book “Are You a Forever Family?” together as a
            conversation starter.
          </p>

          <p>
            Often behind the question is fear. Fear of what harm might be done
            to their biological children. I encourage you to verbalize what
            dangers you’re afraid of and then come together as a couple (if
            you’re in a relationship) and agree on what behaviors you will be
            unable to accept.
          </p>

          <h2>When do foster children become waiting children?</h2>
          <p>
            Similar to other children in foster care, waiting children were
            placed in foster care due to abuse, neglect or death that left them
            unable to reside with their biological family. But after a series of
            court hearings and attempts towards stability, the biological
            family’s parental rights were taken away so the child does not
            reunify with their biological family.
          </p>

          <h2>Who can adopt waiting children?</h2>
          <p>
            <a
              href="https://www.davethomasfoundation.org/about-us/faq/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Most adults (single or married) who are at least 21 years-old
            </a>{" "}
            can adopt waiting children. The list of requirements vary by state
            but you don’t need a large amount of cash or a big house, just a
            caring heart and stable home.
          </p>

          <h2>Do I need to foster?</h2>
          <p>
            To be able to adopt waiting children, you need to live together with
            them for 6 months before going to court to finalize the adoption.
            During that time you will actually be serving as the child(ren)’s
            foster parent. But once the adoption is finalized, you can
            discontinue the foster parent paperwork.
          </p>

          <h2>
            Can I become a foster parent and hope that I can adopt the
            child(ren) I foster?
          </h2>
          <p>
            If your intent is to adopt children, then adopt children. If your
            intent is to foster and care for a child(ren) temporarily, foster.
            Please do not become a foster parent to foster a child only to hope
            you can eventually adopt them.
          </p>

          <h2>Am I too old?</h2>
          <p>
            There is no maximum age to adopt.{" "}
            <a
              href="https://spotifyanchor-web.app.link/e/RfUCsLL5Iub"
              target="_blank"
              rel="noopener noreferrer"
            >
              Robin who talked about her experience adopting a teenager on The
              Forgotten Adoption Option Podcast
            </a>{" "}
            was 49 years old when she adopted through foster care.
          </p>

          <h2>Can I be single?</h2>
          <p>
            Yes!{" "}
            <a
              href="https://www.davethomasfoundation.org/about-us/faq/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Most adults (single or married) who are at least 21 years-old
            </a>{" "}
            can adopt waiting children.
          </p>

          <h2>What if I have mental health issues?</h2>
          <p>
            Are you getting professional help for those mental health issues?
            Children who have gone through the foster care system often have
            mental health needs. I think having personal mental health
            experience makes you very qualified to empathize and parent waiting
            children.
          </p>

          <h2>
            What kinds of resources are available to children in foster care?
          </h2>
          <p>
            This answer varies by state, by children who have spent time in
            foster care can be eligible for state health insurance through
            adulthood, free childcare via state approved agencies, access to
            food pantries and food stamps, as well as special scholarships for
            continued education. Your licensing worker, fellow families who have
            adopted through foster care, and online communities are the best
            places to get local details.
          </p>

          <h2>What do I do if my family and friends are not supportive?</h2>
          <p>
            Recognize this is your journey. In my experience, some friends and
            family were excited to meet my children and eager to support us.
            Others were unsure and sometimes awkward because they just didn’t
            know how to interact or what the appropriate language was. We did
            have some friends and family that asked some off-putting questions.
            At the time, those questions felt hard to receive. But I’ve learned
            part of my role as a future adoptive parent is to educate, set
            boundaries, and love my children well.
          </p>

          <h2>How do I take parental leave as I navigate this process?</h2>
          <p>
            Some employers offer parental leave or adoption leave. When I was
            going through the licensing process, neither my or my husband’s
            employer offered adoption benefits. We customized{" "}
            <a
              href="https://www.davethomasfoundation.org/library/sample-adoption-benefits-proposal-for-employees/"
              target="_blank"
              rel="noopener noreferrer"
            >
              templates from the Dave Thomas Foundation for Adoption
            </a>{" "}
            and presented them to our employers. As a result, we were both given
            paid time off and a host of support from our colleagues.
          </p>

          <h2>How do I find a licensing agency?</h2>
          <p>
            One of the best places to start is{" "}
            <a
              href="https://adoptuskids.org/adoption-and-foster-care/how-to-adopt-and-foster/state-information"
              target="_blank"
              rel="noopener noreferrer"
            >
              AdoptUSKids
            </a>
            . See&nbsp;
            <a href="" target="_blank" rel="noopener noreferrer">
              Step 2
            </a>
            &nbsp;for more on finding a licensing agency.
          </p>

          <h2>What do I do if my paperwork is lost?</h2>
          <p>
            Paperwork can be misplaced due to high turnover of licensing
            workers. A good practice is to take pictures, scan the documents, or
            always have an email trail for any documents you submit as part of
            the home study process.
          </p>

          <h2>
            What do I do if I submitted an application and I’ve received no
            reply?
          </h2>
          <p>
            It can take 4-6 weeks to get a response. Following up to ensure your
            application has been received is a good approach to make sure you
            really are waiting to be reviewed.
          </p>

          <h2>
            How can I know more information about adoptable children I saw
            online?
          </h2>
          <p>
            What you see online is called a “short profile”. In order to be able
            to read more about child(ren), you need to first be licensed in
            order to get access to the “long profile” (which can be pages and
            pages of additional information).
          </p>

          <h2>
            I saw child(ren) available for adoption through a news segment or
            online and I am not licensed. Can I adopt these children?
          </h2>
          <p>
            If you meet the{" "}
            <a
              href="https://dss.mo.gov/cd/adoption/becoming-an-adoptive-parent.htm"
              target="_blank"
              rel="noopener noreferrer"
            >
              requirements to get licensed to adopt
            </a>
            , yes you can adopt children. Whether you adopt the exact child(ren)
            you saw on the news segment or online is possible but sometimes not
            likely. If others who are licensed apply while you go through the
            licensing process, it is likely the others who were already licensed
            will get first priority. However, there are 117,000 children who are
            currently needing a forever family. Sometimes what we see through
            the news or online inspires us to take action so we can help someone
            like the one we read or heard about.
          </p>

          <h2>
            I connected with a profile for a sibling group or child that I read
            online. Can I adopt these children?
          </h2>
          <p>
            The first step is to sign up to get licensed. This involves
            completing an application, doing a certain number of hours of
            training (which depends on your state), and preparing your home for
            child(ren). The process is funded by the state (meaning offered at
            no cost to you) and takes 4-6 months. I often like to encourage
            future adoptive families to remember who they are doing this for and
            to not let the process deter them.
          </p>

          <h2>
            Do I need to have contact with the child(ren)’s biological family?
          </h2>
          <p>
            It depends. Is the child legally-free to be adopted (on AdoptUSKids
            or the Heart Gallery of America) or are they in a legal-risk
            situation (you usually hear about these opportunities once you’re
            licensed). If a child is legally-free to be adopted, there might be
            specific biological family members that the team will ask you to
            maintain contact with for the benefit of the child. In Missouri,
            which is a closed adoption state, after legalizing the adoption of
            my two children we stopped contact with the biological family.
            However, we do openly let our children know we are happy to help
            them get back in contact with their biological family when they are
            older. But for now, we prefer to have boundaries.
          </p>

          <h2>I’m afraid of the unknown. How do I know I’m ready to adopt?</h2>
          <p>
            My husband’s response to this when I brought up the topic as we were
            getting licensed was, “Do you think you would have the same fear if
            we had biological children?” The truth of the matter is, there are
            unknowns when it comes to raising children in general. Children who
            have been in the foster care system have often experienced trauma,
            abuse, neglect, and/or loss. Knowing who to ask for help (your
            support system, the licensing worker, professionals) and being
            willing to advocate for your child(ren) are common traits in
            adoptive parents.
          </p>

          <h2>
            Won’t my heart get broken if the child(ren) returns to their
            biological family?
          </h2>
          <p>
            Children who are waiting in foster care to be adopted are legally
            wards of their state. This means, their biological parents no longer
            legally have parental rights. If you adopt a child or children who
            are waiting, there is no risk of them returning to their biological
            family. If you decide to serve as a foster parent, this risk is
            there.
          </p>
        </article>
      </Container>
    </main>
  );
}
