// eslint-disable-next-line import/extensions
import step2Task1 from "./step2_task1_data.js";
// eslint-disable-next-line import/extensions
import step2Task2 from "./step2_task2_data.js";
// eslint-disable-next-line import/extensions
import step2Task3 from "./step2_task3_data.js";
// eslint-disable-next-line import/extensions
import step2Task4 from "./step2_task4_data.js";
// eslint-disable-next-line import/extensions
import step2Task5 from "./step2_task5_data.js";
// eslint-disable-next-line import/extensions
import step2Task6 from "./step2_task6_data.js";

const STEP2TASK1 = step2Task1.content;
const STEP2TASK2 = step2Task2.content;
const STEP2TASK3 = step2Task3.content;
const STEP2TASK4 = step2Task4.content;
const STEP2TASK5 = step2Task5.content;
const STEP2TASK6 = step2Task6.content;

const WPM = 238;
function WordCount(str) {
  return str.split(" ").length;
}

function taskCount(arr) {
  let totalWord = 0;
  for (let i = 0; i < arr.length; i += 1) {
    totalWord += WordCount(arr[i]);
  }
  return totalWord;
}

const MINREADSTEP2TASK1 =
  Math.round(taskCount(STEP2TASK1) / WPM) > 0
    ? Math.round(taskCount(STEP2TASK1) / WPM)
    : 1;

const MINREADSTEP2TASK2 =
  Math.round(taskCount(STEP2TASK2) / WPM) > 0
    ? Math.round(taskCount(STEP2TASK2) / WPM)
    : 1;

const MINREADSTEP2TASK3 =
  Math.round(taskCount(STEP2TASK3) / WPM) > 0
    ? Math.round(taskCount(STEP2TASK3) / WPM)
    : 1;

const MINREADSTEP2TASK4 =
  Math.round(taskCount(STEP2TASK4) / WPM) > 0
    ? Math.round(taskCount(STEP2TASK4) / WPM)
    : 1;

const MINREADSTEP2TASK5 =
  Math.round(taskCount(STEP2TASK5) / WPM) > 0
    ? Math.round(taskCount(STEP2TASK5) / WPM)
    : 1;
const MINREADSTEP2TASK6 =
  Math.round(taskCount(STEP2TASK6) / WPM) > 0
    ? Math.round(taskCount(STEP2TASK6) / WPM)
    : 1;
export {
  MINREADSTEP2TASK1,
  MINREADSTEP2TASK2,
  MINREADSTEP2TASK3,
  MINREADSTEP2TASK4,
  MINREADSTEP2TASK5,
  MINREADSTEP2TASK6,
};
