/* eslint-disable react/require-default-props */
import React from "react";
import { Link } from "react-router-dom";

interface iHeaderLink {
  className?: string;
  children: React.ReactNode;
  endpoint?: string;
  onClick?: () => void;
}

export default function HeaderLink({
  endpoint,
  className,
  children,
  onClick,
}: iHeaderLink) {
  if (endpoint?.match("https")) {
    return (
      <a
        href={endpoint}
        target="_blank"
        rel="noreferrer"
        className={`${className} hover:underline underline-offset-2 text-primary`}
      >
        {children}
      </a>
    );
  }

  return (
    <Link
      to={endpoint || ""}
      onClick={onClick}
      className={`${className} hover:underline underline-offset-2 text-primary`}
    >
      {children}
    </Link>
  );
}
