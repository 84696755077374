import { useState } from "react";
import HeadphonesIcon from "@mui/icons-material/Headphones";
import TelegramIcon from "@mui/icons-material/Telegram";
import LocalLibraryIcon from "@mui/icons-material/LocalLibrary";
import { Container } from "../UIComponents/Container";
import FooterContactList from "../../content/FooterContent/FooterContactList";
import FooterPodcastList from "../../content/FooterContent/FooterPodcastList";
import FooterBookList from "../../content/FooterContent/FooterBookList";
import { iTabList } from "../../types/footerTabTypes";

export default function FooterMobile() {
  const footerTabLists: iTabList[] = [
    {
      title: "Contact",
      icon: <TelegramIcon />,
      element: <FooterContactList />,
    },
    {
      title: "Podcast",
      icon: <HeadphonesIcon />,
      element: <FooterPodcastList />,
    },
    {
      title: "Books",
      icon: <LocalLibraryIcon />,
      element: <FooterBookList />,
    },
  ];

  const [footerSliderOpen, setFooterSliderOpen] = useState(false);

  const [selectedTab, setSelectedTab] = useState<iTabList | undefined>(
    undefined
  );

  const handleChangeFooterSelection = (newValue: iTabList | undefined) => {
    setSelectedTab(newValue);
    setFooterSliderOpen(true);
  };

  const handleCloseFooterSlider = () => {
    setFooterSliderOpen(!footerSliderOpen);
    setSelectedTab(undefined);
  };

  return (
    <>
      {footerSliderOpen && (
        <div
          data-state={footerSliderOpen ? "open" : "closed"}
          className="transition-opacity duration-150 ease-in-out fixed inset-0 bg-gray-950/70 z-[10] text-white flex justify-center data-[state=open]:animate-fadeIn data-[state=closed]:animate-fadeOut"
          onClick={handleCloseFooterSlider}
          onKeyDown={handleCloseFooterSlider}
          role="button"
          tabIndex={0}
          aria-label="backdrop"
        >
          <button
            type="button"
            className="bg-primary rounded-xl px-2 py-1 text-sm h-7 mb-auto mt-64"
          >
            Tap To Close
          </button>
        </div>
      )}
      <Container className="fixed bottom-0 left-0 right-0 z-10 !p-0 rounded-b-none">
        <div className="transition-all border-b border-accent-light flex gap-5 justify-around">
          {footerTabLists.map(tabList => {
            const activeTab =
              typeof selectedTab === "object" &&
              tabList.title === selectedTab.title;

            return (
              <button
                key={tabList.title}
                type="button"
                onClick={() => handleChangeFooterSelection(tabList)}
                className={`transition-color duration-150 ease-in-out flex flex-col text-sm items-center justify-center px-5 py-3 ${
                  activeTab
                    ? "text-primary border-b-8 border-primary"
                    : "text-gray-500 border-b-8 border-transparent"
                }`}
              >
                <span>{tabList.icon}</span>
                <span className="uppercase font-medium">{tabList.title}</span>
              </button>
            );
          })}
        </div>
        <div
          data-state={footerSliderOpen ? "open" : "closed"}
          className="flex items-center justify-evenly data-[state=open]:m-4 data-[state=closed]:m-0 transition-height duration-150 ease-in-out data-[state=open]:h-72 data-[state=closed]:h-0 overflow-y-auto"
        >
          {footerSliderOpen &&
            selectedTab &&
            typeof selectedTab === "object" &&
            selectedTab.element}
        </div>
      </Container>
    </>
  );
}
