import { Container } from "../components/UIComponents/Container";
import Breadcrumbs from "../components/UIComponents/Breadcrumbs";
import useMediaQuery from "../hooks/useMediaQuery";
import { ArticleHeader } from "../components/UIComponents/Typography";

export default function About() {
  const isSmUp = useMediaQuery("(min-width: 640px)");

  return (
    <main>
      <Container className="flex flex-col flex-nowrap justify-center max-w-[1200px] w-[95%] md:w-[80%]">
        <article className="relative">
          <Breadcrumbs separator="›" crumbs={["Home", "About", "About App"]} />

          {!isSmUp && (
            <div className="relative flex justify-center h-[150px] mt-3">
              <img
                src="./images/welcomeHeadshot.png"
                alt="Marcy wearing an adopt shirt"
                className="w-[150px] h-[150px] z-[1]"
              />
            </div>
          )}

          <ArticleHeader>About This App</ArticleHeader>

          <p>
            This is your comprehensive digital guide for navigating the foster
            care adoption process.
          </p>

          <p>
            I hope you find the layout to be really easy to follow and the
            resources to be insightful.
          </p>

          <p>
            My goal is to equip you so you know what you’re getting into, how to
            get through it, and to be available for questions and support as
            they arise. This app is designed as a to do list so that you can see
            the big picture and understand where you are in the process.
          </p>

          <h2>Your Heart</h2>

          <p>
            For some, the emotion and desire to adopt can be quite strong, then
            nerve-racking, then overwhelming. Sometimes all at the same time. It
            is easy to dismiss your desire merely because the process is scary.
            My intent is to walk you through the process as quickly or slowly as
            you’d like to go through the steps.
          </p>

          <p>
            First, I need to be brutally honest: Adopting children through the
            foster care system is both unique and rewarding. Some of your
            closest friends and family might surprise you at how much they “get”
            it or by how much they don’t “get” it. Adopting children who have
            gone through the experience of being in foster care is going to take
            you on an emotional and mental journey. At points, you’ll feel
            excited, like you’re doing a lot of waiting, or like your heart is
            being ripped out of your chest.
          </p>

          <p>What Inspired This App?</p>

          <p>
            After blogging about adopting a sibling pair, my husband and I began
            coaching future adoptive families in our living room. The pandemic
            shifted these conversations to Zoom calls and receiving a stimulus
            payment allowed us to scale those conversations into a book - “The
            Forgotten Adoption Option: A Self-Reflection and How-To Guide for
            Pursuing Foster Care Adoption.” A month later I found myself
            launching a podcast.
          </p>

          <p>
            With a strong desire for every child who is waiting to be adopted, I
            stepped into the pageant world to use the spotlight to find forever
            families. After receiving the United States of America Pageant’s
            Mrs. People’s Choice Award, a children’s book (“Are You a Forever
            Family?”) sprouted in my heart.
          </p>

          <p>
            Part of preparing for pageant nationals was coming up with a legacy
            project. I was told some contestants write a book or launch a
            podcast. My coach helped me search my heart for something unique I
            could do. This is it! Having reskilled into technology, I could
            design an app as a way to find more forever families. I partnered
            with a global, passionate team of volunteer developers and designers
            to create what you are exploring here today.
          </p>

          <p>
            We are hopeful that the content is useful to you and we are open to
            your feedback.
          </p>

          <p>
            Anytime you have a question or an idea, just use the Contact button
            on the bottom of your screen to ask and share.
          </p>

          <p>
            Many quit this journey because it’s overwhelming and rocky. I think
            we can all agree that the children who are waiting to be adopted are
            worth pushing through the process.
          </p>
        </article>
      </Container>
    </main>
  );
}
