import { ArrowBack } from "@material-ui/icons";
import { titleCase } from "title-case";
import { iDefaultProp, iContentLayout } from "../../types/interfaces";
import { OutlinedButton } from "./Buttons";
import ScrollButton from "../ScrollButton";

export function Container({ className, children }: iDefaultProp) {
  return (
    <section
      className={`${className} container mx-auto px-4 py-6 sm:px-10 sm:py-12 prose dark:prose-invert bg-background rounded-md shadow`}
    >
      {children}
    </section>
  );
}

export function ContentContainer({ title, children }: iContentLayout) {
  return (
    <div className="flex prose dark:prose-invert prose-li:list-none max-w-none sm:z-0 w-full h-full sm:col-span-8 top-0 left-0 z-10 motion-reduce:animate-fadeIn sm:animate-none bg-accent-dark/80 sm:bg-transparent">
      <Container className="max-w-none motion-reduce:animate-enterFromRight sm:animate-none rounded-none">
        <OutlinedButton to="/steps" className="w-fit sm:hidden text-primary">
          <ArrowBack />
        </OutlinedButton>
        <h2 className="mt-6 sm:mt-8">{titleCase(title)}</h2>
        {children}
        <ScrollButton />
      </Container>
    </div>
  );
}
