import { Dispatch } from "react";
import { Link } from "react-router-dom";
import AnchorIcon from "@mui/icons-material/Anchor";
import AppleIcon from "@mui/icons-material/Apple";
import SpotifyIcon from "@mui/icons-material/Contactless";

interface iOutlinedButton extends React.HTMLProps<HTMLButtonElement> {
  to?: string;
  className?: string;
  children: React.ReactNode;
  onClick?: Dispatch<unknown>;
}
interface iContentMediaButton extends React.HTMLProps<HTMLAnchorElement> {
  className?: string;
  platform: string;
}
interface iFooterListButton extends React.HTMLProps<HTMLButtonElement> {
  className?: string;
  children: React.ReactNode;
}
interface iPrimaryButton extends React.HTMLProps<HTMLButtonElement> {
  children: React.ReactNode;
  clickFunction?: Dispatch<unknown>;
  className?: string;
  disabled: boolean;
}

export function OutlinedButton({
  to,
  className,
  children,
  onClick,
}: iOutlinedButton) {
  return (
    <Link
      to={to || ""}
      onClick={onClick}
      className={`${className} px-6 py-1 border rounded-md border-primary`}
    >
      {children}
    </Link>
  );
}
OutlinedButton.defaultProps = {
  to: "",
  className: "",
  onClick: null,
};

export function ContentMediaButton({
  href,
  platform,
  className,
}: iContentMediaButton) {
  let icon: React.ReactNode;
  let color: string;

  switch (platform) {
    case "apple":
      icon = <AppleIcon />;
      color = "bg-gray-500";
      break;
    case "spotify":
      icon = <SpotifyIcon />;
      color = "bg-green-500";
      break;
    default:
      icon = <AnchorIcon />;
      color = "bg-violet-500";
  }

  return (
    <a
      href={href}
      className={`${className} flex place-items-center p-3 rounded-md text-white ${color}`}
    >
      {icon}
    </a>
  );
}
ContentMediaButton.defaultProps = {
  className: "",
};

export function FooterListButton({
  className,
  children,
  href,
  target,
  rel,
}: iFooterListButton) {
  return (
    <li>
      <a
        href={href}
        target={target || "_blank"}
        rel={rel || "noreferrer"}
        className={`${className} flex px-2 py-3 hover:bg-primary/30 border-l-8 hover:border-l-8 border-white/0 hover:border-primary [&>svg]:mr-6`}
      >
        {children}
      </a>
    </li>
  );
}
FooterListButton.defaultProps = {
  className: "",
};

export function PrimaryButton({
  children,
  clickFunction,
  className,
  disabled,
}: iPrimaryButton) {
  return (
    <button
      type="button"
      className={`w-full py-1 text-white text-sm bg-primary rounded disabled:brightness-75 duration-500 ${className}`}
      onClick={clickFunction}
      disabled={disabled}
    >
      {children}
    </button>
  );
}
PrimaryButton.defaultProps = {
  clickFunction: null,
  className: "",
};
