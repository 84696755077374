import { contentTypes } from "../../utility/constants";

export default {
  type: contentTypes.TEXT,
  title: "Short vs Long Listings",
  content: (
    <article>
      <p>
        When you read a “short profile” for a child and are interested in
        learning more, you can tell your licensing worker, and they will try to
        obtain the long profile (pages of information) on the child(ren).
      </p>

      <p>
        The following two paragraphs are the introductions I read about my
        children in an email thread. Not a single picture was included. It was
        from reading these descriptions, that my husband and I made a decision
        to apply to be the forever family for these siblings. Literally just a
        few hours after getting these paragraphs, our names were submitted
        alongside nine other families.
      </p>

      <p>
        <em>
          Meet our fun loving little man. Big Brother is a smaller 4 year old
          boy with light brown hair and blue eyes. Recently he has filled out
          and put on some weight. He can be shy around new people but once he
          gets to know you he is very friendly. He likes to play with his trucks
          and watch cartoons. He can be a rather picky eater at times. He is
          healthy but does suffer from occasional issues with Asthma. He gets
          along well with other children and has no major behavior issues. TPR
          has not been completed at this time but is the case goal.
        </em>
      </p>

      <p>
        <em>
          Meet our cuddler. Little Sister is a big girl and will soon be 3 years
          old. She has light brown hair and big blue eyes. She weighs almost as
          much as her older brother who will be five years old in October. She
          can be shy around new people but once she gets to know you she is very
          friendly. She loves to play with her toys and watch Dora. She is a
          good eater who likes about any kind of food. She has no medical issues
          and is current on her immunizations. She is a very loving child that
          gets along well with other children. TPR has not been completed at
          this time but is the case goal.
        </em>
      </p>
    </article>
  ),
};
