import { contentTypes } from "../../utility/constants";

export default {
  type: contentTypes.PODCAST,
  title: "Worth Listening",
  playlist:
    "https://open.spotify.com/embed/playlist/4f4oEtnDX5huM6BEDK28QM?si=4a8216fad3c443d7",
  resources: [
    {
      title:
        "HOW to see professional photographs and bios of children waiting to be adopted through foster care",
      links: [
        {
          link: "https://podcasts.apple.com/us/podcast/how-to-see-professional-photographs-and-bios-of/id1542072701?i=1000556299659",
          platform: "apple",
        },
        {
          link: "https://spotifyanchor-web.app.link/e/4NFfPzFOstb",
          platform: "spotify",
        },
      ],
    },
  ],
};
