import { contentTypes } from "../../utility/constants";

export default {
  type: contentTypes.TEXT,
  title: "Prepare Your Heart",
  content: (
    <article>
      <p>
        I am not sure I consciously paused to prepare my heart, but there were
        people who were in my life that stepped in to walk alongside my family
        during this process. I would get texts and emails from friends and
        family asking how things were going and sending prayers. There were
        other families who we had taken classes with that were a great source of
        insight and encouragement, and there were a few adoptive moms that had
        taken me under their wing. I have the cell numbers for the adoptive moms
        in my phone, and to this day no matter how random or challenging an
        issue I face, I can text either one of them and receive empathy and
        resources.
      </p>
    </article>
  ),
};
