import { contentTypes } from "../../utility/constants";
import { TextCaption } from "../../components/UIComponents/Typography";

export default {
  type: contentTypes.TEXT,
  title: "Get Professional Help for Wounds",
  content: (
    <article>
      <p>
        <em>
          “Vulnerability is not about fear and grief and disappointment. It is
          the birthplace of everything we’re hungry for.”
        </em>{" "}
        - Brené Brown
      </p>
      <p>
        Dealing with your “stuff” is no small task. Some of us grew up being
        encouraged to share our feelings and experiences, while others of us
        felt things but never shared them with anyone for one reason or another.
        Unpacking those tucked away memories can conjure up other insecurities
        and fears that you might not even have known were inside you.
      </p>
      <p>
        Debra, an adoptive parent (whose biological daughter and I were in
        college together), shares these words: “If you’re [trying to adopt] to
        feel love, you’re in it for the wrong reasons. It’s a commitment. You
        just have to love and you don’t necessarily get the love back.”
      </p>
      <p>
        What are things you need to get emotionally clear on? What steps can you
        take to get the support and professional help you need to work through
        these things?
      </p>
      <p>
        Author and pastor Rick Warren encourages people who are desiring a
        committed relationship (read: marriage, dating, adopting a child) to not
        move forward “until your own emotional hurts are healed or at least in
        the healing process. Get rid of the bitterness. Get rid of the anger
        problem. Get rid of your baggage.”<sup>[1]</sup> Being an adoptive
        parent requires that you be available to help support your child(ren)
        through their own wounds. If you can address yours, you are in a better
        position to speak from experience, walk with them, and encourage them to
        work through theirs.
      </p>
      <p>
        Finding a therapist or engaging in mental health services can feel
        overwhelming. Asking trusted friends and family for their
        recommendations can be helpful. If you have medical insurance, you can
        contact your insurance company to get a list of in-network providers in
        your state. If you prefer virtual visits, betterhelp.com comes
        recommended by a friend who is a therapist.
      </p>

      <p>
        [1]{" "}
        <TextCaption>
          Warren, R. (2014). You make me crazy. YouVersion app.
        </TextCaption>
      </p>
    </article>
  ),
};
