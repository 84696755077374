import React, {
  ReactNode,
  createContext,
  useMemo,
  useReducer,
  useEffect,
} from "react";
import { useNavigate } from "react-router-dom";
import { iDataStoreState, Action } from "../types/dataStoreTypes";
import { setAuthObserver, DbGetUser } from "../utility/firebase";

const initialState = {
  userId: null,
  displayName: null,
  userEmailVerified: false,
  mapData: null,
};

const DataContext = createContext<{
  appState: iDataStoreState;
  dispatch: React.Dispatch<Action>;
}>({
  appState: initialState,
  dispatch: () => null,
});

const reducerMethod = (appState: iDataStoreState, action: Action) => {
  switch (action.type) {
    case "SET_USER":
      return {
        ...appState,
        userId: action.payload.userId,
        displayName: action.payload.displayName,
        userEmailVerified: action.payload.userEmailVerified,
      };

    case "SET_MAP_DATA":
      return {
        ...appState,
        mapData: action.payload.mapData,
      };

    default: {
      return appState;
    }
  }
};

function DataStoreProvider({ children }: { children: ReactNode }) {
  const [appState, dispatch] = useReducer(reducerMethod, initialState);
  const stateMemo = useMemo(() => ({ appState, dispatch }), [appState]);
  const navigate = useNavigate();

  useEffect(() => {
    setAuthObserver(async user => {
      if (user == null) {
        dispatch({
          type: "SET_USER",
          payload: {
            userId: null,
            displayName: null,
            userEmailVerified: false,
          },
        });
      } else {
        const userData = await DbGetUser(user.uid);
        dispatch({
          type: "SET_USER",
          payload: {
            userId: userData?.uid,
            displayName: userData?.displayName,
            userEmailVerified: user.emailVerified,
          },
        });
        navigate("/");
      }
    });
  }, []);

  return (
    <DataContext.Provider value={stateMemo}>{children}</DataContext.Provider>
  );
}

export { DataContext, DataStoreProvider };
