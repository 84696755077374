import { TextCaption } from "../../components/UIComponents/Typography";
import { contentTypes } from "../../utility/constants";

export default {
  type: contentTypes.TEXT,
  title: "Decide Which License",
  description: "See table on page 30",
  content: (
    <article>
      <p>
        You’ll want to decide which type of commitment you’re wanting to make so
        you know what type of licensing you’re aiming for<sup>[1]</sup>. This
        helps you decide the type of training you need.
      </p>

      <table
        aria-label="Type of Care Comparison Table"
        className="w-full table-auto border-collapse border-spacing-2 rounded shadow-md text-sm"
      >
        <thead className="text-left">
          <tr>
            <th>Type of Care/License</th>
            <th>Commitment</th>
            <th>Leads to Adoption</th>
            <th>Training Required</th>
          </tr>
        </thead>
        <tbody className="[&>tr:last-child]:border-b-0 [&>tr]:border-t [&>tr]:border-b [&>tr]:border-primary-50">
          <tr>
            <td>Adopt-Only: Legally Free Child(ren)</td>
            <td>for life</td>
            <td>yes</td>
            <td>foster care training & adoption training</td>
          </tr>
          <tr>
            <td>Adopt-Only: Legal Risk Child(ren)</td>
            <td>potentially indefinitely but not guaranteed</td>
            <td>potentially but not guaranteed</td>
            <td>foster care training & adoption training</td>
          </tr>
          <tr>
            <td>Foster Care</td>
            <td>a few days, a few months, temporary or indefinitely</td>
            <td>sometimes</td>
            <td>foster care training</td>
          </tr>
          <tr>
            <td>Kinship</td>
            <td>temporary or permanent</td>
            <td>sometimes</td>
            <td>varies by state</td>
          </tr>
          <tr>
            <td>Respite</td>
            <td>a few hours - a few days</td>
            <td>sometimes</td>
            <td>varies by state, might be online videos</td>
          </tr>
        </tbody>
      </table>

      <p>
        <strong>Adopt</strong>
      </p>

      <p>
        This option means you are interested in adopting a child or children.
        The child(ren) could be in the custody of the state because their
        biological parents’ parental rights have been terminated. These children
        are “legally free” to be adopted, meaning they have no risk of returning
        to their biological family. Or, the child(ren) could be “legal risk”
        which means they are nearing being “legally free” to be adopted but at
        the moment, there are a lot of legal processes that are still up in the
        air. The child(ren) is considered “legal risk” because while the current
        status looks like they will need an adoptive home, the child(ren)’s
        biological family could start demonstrating signs of stability and/or
        safety. If you choose to pursue an adopt-only license (also known as
        foster-to-adopt in some areas) you will need to complete both foster
        care and adoptive care licensing requirements. Some call this “dual
        licensing.”
      </p>

      <p>
        <strong>Foster</strong>
      </p>

      <p>
        Obtaining a foster license means you would like to open your home to be
        a temporary, loving home for children who have experienced trauma or
        abuse. While in your care, the child(ren) might return to their
        biological family (reunification) or they might become eligible for
        adoption. If the child(ren) becomes eligible for adoption, as the
        child(ren)’s foster family you can be considered but you are not
        guaranteed to be the adoptive home. Many factors are considered,
        including but not limited to if someone in the child(ren)’s extended
        family steps forward to adopt, or if another family is a better fit due
        to cultural or emotional needs.
      </p>

      <p>
        <strong>Kinship</strong>
      </p>

      <p>
        Kinship is the term for when a family member (grandparent, aunt/uncle,
        etc.) steps in to take care of a child(ren). Some kinship placements are
        for a few weeks or months while others are indefinite. It depends on the
        stability and safety of the biological family. Think foster care but
        with biological extended family members.
      </p>

      <p>
        <strong>Respite</strong>
      </p>

      <p>
        Respite is a way to provide a break for foster parents. You take
        child(ren) for an overnight to up to two weeks at a time. Foster Parents
        are given so many days of respite care per calendar year. Read: You get
        paid to be a respite provider for each day of care you provide. Respite
        is a great place to start if you are unsure and/or if you want to pursue
        adopt-only (again this is known as foster-to-adopt in some areas) but
        you are not currently a parent. My husband and I were respite providers
        and took care of a pair of brothers and a single boy on a few occasions.
        We provided respite care for five days once, three days once, and then
        two days once.
      </p>

      <p>
        [1]{" "}
        <TextCaption>
          Focus on the Family. (2020). Types of foster care. Retrieved August
          17, 2020 from: https://www.waitnomore.org/types-of-foster-care/.
        </TextCaption>
      </p>
    </article>
  ),
};
