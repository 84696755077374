export default {
  title: "",
  content: [
    "Similar to having a personal mission statement, taking time to write out, print, and have a copy to remind you of why you’re doing this will help keep you going even when the process gets long and the emotional roller coaster has you questioning why you even began this process.",
    "You can type or write this out. You can even get creative and add color and graphics to you.",
    "Consider answering these questions:",
    "What is your intention? Are you hoping to adopt? How many children? What ages?",
    "What do you want to adopt? What sparked this desire?",
    "What makes right now the right time to do this?:",
    "How do you know this is the right path for you?",
    "Where will you turn when things are difficult or the future looks uncertain?",
  ],
};
