import { contentTypes } from "../../utility/constants";

export default {
  type: contentTypes.TEXT,
  title: "Resources",
  content: (
    <article>
      <p>
        Along the way we have needed additional help and support. Here are
        resources that have been helpful to our journey as an adoptive family:
      </p>

      <ul>
        <li>
          <strong>Mightier -</strong> mighter.com <br />
          Created by Harvard Medical University and Boston Children’s Hospital,
          children learn emotional regulation by playing games on a tablet for
          15 minutes three days a week. Monthly parent(s) talk to a Mightier
          Team social worker to discuss changes they are seeing, changes they
          hope for, and strategies to achieve them.
        </li>
        <li>
          <strong>
            “The Connected Child: Bring Hope and Healing to Your Adoptive
            Family”
          </strong>{" "}
          <em>by Karen Purvis, David Cross, and Wendy Lyons Sunshine</em> <br />
          The Connected Child will help you (1) build bonds of affection and
          trust with your adopted child, (2) effectively deal with any learning
          or behavioral disorders, (3) discipline your child with love without
          making him or her feel threatened.
        </li>
        <li>
          <strong>Hope for the Journey Conference</strong> (
          <em>formerly the Empowered to Connect Conference</em>) - showhope.org{" "}
          <br />
          Built off the work of Dr. Karen Purvis, this in-person and simulcast
          event equips parents, caregivers, ministry leaders, and care
          professionals to better serve children impacted by adoption and foster
          care. At this event, you will learn how to use their evidence-based
          TBRI method.
        </li>
        <li>
          <strong>“Parenting Through the Mirror”</strong>{" "}
          <em>by S. Courtney Lawson</em> <br />
          Learn how to improve your parenting skills while you learn to improve
          yourself.
        </li>
        <li>
          <strong>Empowering Parents</strong> - empoweringparents.com <br />
          Online learning programs and parenting coaching to help you manage
          difficult behaviors in children ages 5 to 25.
        </li>
      </ul>
    </article>
  ),
};
