import { contentTypes } from "../../utility/constants";

export default {
  type: contentTypes.TEXT,
  title: "Complete State Application",
  description: "Easy place to find each state?",
  content: (
    <article>
      <p>
        You’ll need to contact a local agency and request an application, or you
        might get lucky and find an agency that provides the application online.
        I reside in Missouri and was able to pull{" "}
        <a
          href="https://drive.google.com/file/d/1ijvSrIlhQ8VC1tUAP_vP7GWNnmm7i65B/view"
          target="_blank"
          rel="noopener noreferrer"
        >
          this application
        </a>{" "}
        from an agency’s website to show you an example. Please note that this
        application may not be up-to-date and is simply being shared to give you
        an idea of what to expect and should not be treated as an official
        application. You’ll want to contact the licensing agency to ask for the
        application, so you know you are filling out the right form and how to
        submit it.
      </p>

      <p>Typical things you will be asked include:</p>

      <ul>
        <li>
          who all lives in your home and their ages <br />
          <em>
            &nbsp;
            {`--> This helps paint a picture of the type of family a child would be
            placed with.`}
          </em>
        </li>
        <li>
          a few references who can speak to your character and ability to care
          for children <br />
          <em>
            &nbsp;
            {`--> Be sure to give these people a heads up because their delayed
            reference might delay your application.`}
          </em>
        </li>
        <li>
          why you are interested in fostering or adopting <br />
          <em>
            &nbsp;
            {`--> So they know your ‘why’.`}
          </em>
        </li>
        <li>
          your monthly income and expenses <br />
          <em>&nbsp;</em>
        </li>
        <li>
          your legal and child abuse and neglect history (if applicable) <br />
          <em>
            &nbsp;
            {`--> Just be upfront and honest. They ask this because they want to
            prevent future abuse and neglect for these children.`}
          </em>
        </li>
      </ul>

      <p>
        One of the first tasks you might be given after submitting your
        application is to complete additional paperwork like&nbsp;
        <a
          href="https://drive.google.com/file/d/1GEZTDG2SzB-mK6hjauz0lZwGN5BuPwRE/view?usp=sharing"
          target="_blank"
          rel="noopener noreferrer"
        >
          this SAFE Home Study
        </a>
        .
      </p>
    </article>
  ),
};
