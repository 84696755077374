import {
  MINREADSTEP1TASK1,
  MINREADSTEP1TASK2,
  MINREADSTEP1TASK3,
  MINREADSTEP1TASK4,
  MINREADSTEP1TASK5,
} from "./Step1/Step1WordCount";
import {
  MINREADSTEP2TASK1,
  MINREADSTEP2TASK2,
  MINREADSTEP2TASK3,
  MINREADSTEP2TASK4,
  MINREADSTEP2TASK5,
  MINREADSTEP2TASK6,
} from "./Step2/Step2Wordcount";

const stepSummary = [
  {
    step: 1,
    altText: "number-one",
    number: 1,
    title: "Prepare Your Heart",
    tasks: 5,
    taskTitlesTimes: [
      {
        taskTitle: "Become aware of what foster care adoption is",
        taskTime: MINREADSTEP1TASK1,
      },
      {
        taskTitle:
          "Ask Yourself (and your significant other if applicable) these questions",
        taskTime: MINREADSTEP1TASK2,
      },
      { taskTitle: "Name Your Fears", taskTime: MINREADSTEP1TASK3 },
      {
        taskTitle: "Get Professional Help for Wounds",
        taskTime: MINREADSTEP1TASK4,
      },
      { taskTitle: "Worth Listening", taskTime: MINREADSTEP1TASK5 },
    ],
  },
  {
    step: 2,
    altText: "number-two",
    number: 2,
    title: "Decide & Apply",
    tasks: 5,
    taskTitlesTimes: [
      { taskTitle: "Write Down Your Why", taskTime: MINREADSTEP2TASK1 },
      { taskTitle: "Decide Which License", taskTime: MINREADSTEP2TASK2 },
      { taskTitle: "Research Licensing Agencies", taskTime: MINREADSTEP2TASK3 },
      { taskTitle: "Complete State Application", taskTime: MINREADSTEP2TASK4 },
      { taskTitle: "Announce to Friends & Family", taskTime: 5 },
      { taskTitle: "Worth Listening", taskTime: MINREADSTEP2TASK6 },
    ],
  },
  {
    step: 3,
    altText: "number-three",
    number: 3,
    title: "Prep",
    tasks: 5,
    taskTitlesTimes: [
      {
        taskTitle: "Docs, Classes, Home Checklist",
        taskTime: MINREADSTEP2TASK5,
      },
      { taskTitle: "Assess Your Home", taskTime: 5 },
      { taskTitle: "Create an Emergency Plan", taskTime: 5 },
      { taskTitle: "Serve as a Respite Provider", taskTime: 5 },
      { taskTitle: "Worth Reading", tasktime: 5 },
      { taskTitle: "Worth Listening", taskTime: 5 },
    ],
  },
  {
    step: 4,
    altText: "number-four",
    number: 4,
    title: "Apply for Children",
    tasks: 3,
    taskTitlesTimes: [
      { taskTitle: "Online Listings", taskTime: 5 },
      { taskTitle: "Short vs Long Listings", taskTime: 5 },
      { taskTitle: "Worth Listening", taskTime: 5 },
    ],
  },
  {
    step: 5,
    altText: "number-five",
    number: 5,
    title: "Interview & Selection",
    tasks: 3,
    taskTitlesTimes: [
      { taskTitle: "Who Is Around the Table", taskTime: 5 },
      {
        taskTitle:
          "Create Your Own Family Book to Introduce Your Family to the Interview Team",
        taskTime: 5,
      },
      { taskTitle: "You Might Not Get Picked the First Time", taskTime: 5 },
    ],
  },
  {
    step: 6,
    altText: "number-six",
    number: 6,
    title: "Meet & Move In Together",
    tasks: 5,
    taskTitlesTimes: [
      { taskTitle: "Get Experience", taskTime: 5 },
      { taskTitle: "Prepare Your Schedule", taskTime: 5 },
      { taskTitle: "Prepare Your Heart", taskTime: 5 },
      {
        taskTitle: "Request Adoption Benefits From Your Employer",
        taskTime: 5,
      },
      { taskTitle: "Resources Available to Your Family", taskTime: 5 },
      { taskTitle: "Worth Listening", taskTime: 5 },
    ],
  },
  {
    step: 7,
    altText: "number-seven",
    number: 7,
    title: "Court Hearing",
    tasks: 3,
    taskTitlesTimes: [
      { taskTitle: "The Celebration", taskTime: 5 },
      { taskTitle: "The Legal Stuff", taskTime: 5 },
      { taskTitle: "Worth Listening", taskTime: 5 },
    ],
  },
  {
    step: 8,
    altText: "number-eight",
    number: 8,
    title: "Your Forever Family",
    tasks: 5,
    taskTitlesTimes: [
      { taskTitle: "The Surprises", taskTime: 5 },
      { taskTitle: "The Struggles", taskTime: 5 },
      { taskTitle: "Firsts", taskTime: 5 },
      { taskTitle: "Resources", taskTime: 5 },
      { taskTitle: "Worth Listening", taskTime: 5 },
    ],
  },
];
export default stepSummary;
