import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useTheme, useMediaQuery } from "@mui/material";

export default function ScrollToTopOnUrlChange() {
  const { pathname } = useLocation();
  const theme = useTheme();
  const isSmallDown = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    if (isSmallDown) return;

    const largeContentView = document.getElementById("large-content-view");
    if (largeContentView !== undefined && largeContentView !== null) {
      largeContentView.scrollTo({
        top: 0,
        left: 0,
      });
    }
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
    });
  }, [pathname, isSmallDown]);

  return null;
}
