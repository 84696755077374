import { useEffect } from "react";
import usePrefersDarkMode from "./usePrefersDarkMode";
import useSafeLocalStorage from "./useSafeLocalStorage";

export default function useDarkMode() {
  const prefersDarkMode = usePrefersDarkMode();
  const [isEnabled, setIsEnabled] = useSafeLocalStorage("dark-mode", undefined);

  const enabled = isEnabled === undefined ? prefersDarkMode : isEnabled;

  useEffect(() => {
    if (window === undefined) return;
    const root = window.document.documentElement;
    if (enabled) {
      root.classList.add("dark");
      root.setAttribute("data-mode", "dark");
    } else {
      root.classList.remove("dark");
      root.removeAttribute("data-mode");
    }
  }, [enabled]);

  return [enabled, setIsEnabled];
}
