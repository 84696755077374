interface iChatReplies {
  className?: string;
  text: string;
}

export function BotReply({ text, className }: iChatReplies) {
  return (
    <div
      className={`${className} py-2 px-4 my-4 max-w-[90%] rounded-xl rounded-tl-none bg-white text-black shadow-sm`}
    >
      {text}
    </div>
  );
}

BotReply.defaultProps = {
  className: "",
};

export function UserReply({ text, className }: iChatReplies) {
  return (
    <div
      className={`${className} py-2 px-4 my-4 max-w-[260px] rounded-xl rounded-br-none ml-auto bg-primary-700 text-white shadow-sm`}
    >
      {text}
    </div>
  );
}

UserReply.defaultProps = {
  className: "",
};
