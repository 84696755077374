import * as Collapsible from "@radix-ui/react-collapsible";
import { CaretDownIcon } from "@radix-ui/react-icons";
import HeaderLink from "./HeaderLink";

interface iCollapsibleMenu {
  title: string;
  submenu: object;
  onClick: () => void;
}

export default function HamburgerCollapsibleMenu({
  title,
  submenu,
  onClick,
}: iCollapsibleMenu) {
  return (
    <Collapsible.Root>
      <Collapsible.Trigger className="text-primary bg-background z-[2] px-4 py-2 group flex select-none items-center justify-between gap-[2px] outline-none">
        {title}{" "}
        <CaretDownIcon className="relative transition-transform duration-[250] ease-in group-data-[state=open]:-rotate-180" />
      </Collapsible.Trigger>
      <Collapsible.Content className="flex flex-col overflow-hidden data-[state=open]:animate-collapsibleSlideDown data-[state=closed]:animate-collapsibleSlideUp">
        {submenu instanceof Array &&
          submenu.map(subItem => (
            <HeaderLink
              key={subItem.title}
              onClick={onClick}
              endpoint={subItem.endpoint}
              className="px-8 py-2"
            >
              {subItem.title}
            </HeaderLink>
          ))}
      </Collapsible.Content>
    </Collapsible.Root>
  );
}
