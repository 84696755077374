import { initializeApp } from "firebase/app";
import {
  getAuth,
  setPersistence,
  browserSessionPersistence,
  browserLocalPersistence,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  signInWithPopup,
  onAuthStateChanged,
  User,
  NextOrObserver,
  GoogleAuthProvider,
  getAdditionalUserInfo,
} from "firebase/auth";
import { getFirestore, doc, setDoc, getDoc } from "firebase/firestore";
import { firebaseConfig } from "./constants";

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

const DbCreateUser = async (documentId: string, displayName: string) => {
  try {
    await setDoc(doc(db, "users", documentId), { displayName });
  } catch (e) {
    throw new Error("Unable to add new user to database.");
  }
};

const DbGetUser = async (documentId: string) => {
  const docSnapshot = await getDoc(doc(db, "users", documentId));
  if (!docSnapshot.exists()) throw new Error("User not found.");
  return docSnapshot.data();
};

const DbGetData = async (collection: string, documentId: string) => {
  const docSnapshot = await getDoc(doc(db, collection, documentId));
  if (!docSnapshot.exists()) throw new Error("Data not found.");
  return docSnapshot.data();
};

const setKeepSignedIn = async (keepSignedIn: boolean) => {
  if (keepSignedIn) {
    await setPersistence(auth, browserLocalPersistence);
  } else {
    await setPersistence(auth, browserSessionPersistence);
  }
};

const createNewUser = async (
  email: string,
  password: string,
  displayName: string,
  keepSignedIn: boolean
) => {
  if (auth == null) return;

  await setKeepSignedIn(keepSignedIn).then(async () => {
    await createUserWithEmailAndPassword(auth, email, password)
      .then(async res => {
        await DbCreateUser(res.user.uid, displayName);
      })
      .catch(e => {
        throw new Error(e);
      });
  });
};

const signInExistingUser = async (
  email: string,
  password: string,
  keepSignedIn: boolean
) => {
  if (auth == null) return;
  await setKeepSignedIn(keepSignedIn).then(async () => {
    await signInWithEmailAndPassword(auth, email, password).catch(e => {
      throw new Error(e);
    });
  });
};

const setAuthObserver = (observerCallback: NextOrObserver<User>) => {
  onAuthStateChanged(auth, observerCallback);
};

const signOutUser = async () => {
  if (auth == null) return;
  await signOut(auth);
};

const googleAuthWithPopup = () => {
  if (auth == null) return;

  const provider = new GoogleAuthProvider();

  signInWithPopup(auth, provider)
    .then(async result => {
      const { user } = result;
      const additionalInfo = getAdditionalUserInfo(result)?.profile
        ?.name as string;

      try {
        await DbGetUser(user.uid);
      } catch (e) {
        if (
          e instanceof Error &&
          e.message.includes("not found") &&
          additionalInfo != null
        ) {
          await DbCreateUser(user.uid, additionalInfo);
        }
      }
    })
    .catch(e => {
      throw new Error(e);
    });
};

export {
  createNewUser,
  signInExistingUser,
  signOutUser,
  setAuthObserver,
  DbCreateUser,
  DbGetUser,
  DbGetData,
  googleAuthWithPopup,
};
