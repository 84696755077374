import { contentTypes } from "../../utility/constants";

export default {
  type: contentTypes.TEXT,
  title: "Prepare Your Schedule",
  content: (
    <article>
      <p>
        As my husband and I were waiting for our application to process, we laid
        in bed one night talking about our potential new lifestyle. It dawned on
        us that a lot was about to change, and this included how our time was
        spent. So we talked about what one to three things we still wanted to be
        priorities in our life. His replies were art, exercise, and date night.
        Mine were sleep and exercise. We have tried our best to honor making
        those priorities a reality for each other as we have stepped into
        parenting our children. Setting up expectations by talking about our
        priorities has made it clear on how to support one another.
      </p>
    </article>
  ),
};
