import { contentTypes } from "../../utility/constants";

export default {
  type: contentTypes.TEXT,
  title: "You Might Not Get Picked the First Time",
  content: (
    <article>
      <p>
        Before getting licensed my husband and I knew of a pair of siblings in
        need of a foster home. We weren’t sure we were meant to be their
        adoptive parents, but we were interested in learning more. After getting
        licensed, we were invited to the staffing for that pair of siblings. We
        were one of two families invited. It felt crazy to be in front of a team
        answering questions about how we would care for children we had never
        met. A few hours later we got a phone call telling us we weren’t
        selected because the other family had an older son who would be a strong
        and needed role model to the siblings. Talk about a flood of emotions
        going through all that! That night my husband and I decided stress
        eating was a way to cope. He headed to the store for a frozen pizza and
        a bag of Oreos. While he was gone, I opened my email and found a message
        about a pair of siblings in another county. The thread only had two
        short paragraphs, no photos. I did not see any red flags in what I read,
        so I drafted a reply to the email. When my husband arrived back home, I
        told him about the pair of siblings. Having been through a lot that day,
        he suggested we sleep on it. The email said the deadline to apply was
        the very next day. I told my husband and also pointed out that our
        license, at that point, was for kids ages 4 and up and this sibling pair
        was a 2-year-old and 4-year-old; we’d have to get our licensing worker
        to get that changed to even be eligible to apply. My husband agreed we
        could submit our interest.
      </p>
      <p>
        About a week later on a Friday afternoon I got a phone call from a
        number I did not recognize. I picked it up. The woman on the other line
        told me 10 families had applied for the sibling pair and we had been
        selected. She pointed out that they thought my husband being a teacher
        and me working in social service (at the time) was a great fit. They
        asked how soon we could meet the children. My husband was at school
        teaching, so there was no way to add him to the call. My heart replied,
        “Tomorrow.” We drove 5 hours the very next day to meet with the social
        worker for my now children. We were given a 5-inch stack file to read
        about what our children had gone through. Then, we met our children and
        their foster family at a park. We played together and had pizza for
        lunch. My husband and I had agreed we would not make a decision on the
        spot but would decide on the drive back home.
      </p>
      <p>The drive back home was filled with hail.</p>
      <p>“You go first,” my husband told me after we got on the highway</p>
      <p>“I have just one question,” I replied.</p>
      <p>“Okay.”</p>
      <p>“Tell me one reason why we shouldn’t do this.”</p>
      <p>
        There was a long pause from both of us. We did not have a single reason.
        We called the social worker and arranged to bring our kids home 5 days
        later.
      </p>
    </article>
  ),
};
