import React, { useState, useRef, useEffect } from "react";
import SendIcon from "@mui/icons-material/Send";
import { v4 as uuidv4 } from "uuid";
import avatar from "../assets/images/avatar.png";
import fetchReply from "../apis/openai";
import { BotReply, UserReply } from "../components/UIComponents/ChatComponents";

interface iChats {
  from: string;
  text: string;
}

export default function ChatBot() {
  const [conversationStr, setConversationStr] = useState<string>("");
  const [userInput, setUserInput] = useState<string>("");
  const [chats, setChats] = useState<iChats[]>([]);

  const chatContainer = useRef<HTMLDivElement>(null);

  const renderUserReply = (input: string) => {
    setChats((prevChats: iChats[]) => [
      ...prevChats,
      { from: "user", text: input },
    ]);
  };

  const renderBotReply = (input: string) => {
    setChats((prevChats: iChats[]) => [
      ...prevChats,
      { from: "bot", text: input },
    ]);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const prompt = `${conversationStr} ${userInput} ->`;
    renderUserReply(userInput);
    setUserInput("");
    setConversationStr(prompt);

    // Fetch and render bot reply
    const response = await fetchReply(prompt);
    setConversationStr(
      (prevConvStr: string) => `${prevConvStr} ${response} \n`
    );
    renderBotReply(response);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUserInput(e.target.value);
  };

  useEffect(() => {
    if (chatContainer.current !== null) {
      chatContainer.current.scrollTop = chatContainer.current.scrollHeight;
    }
  }, [chats]);

  return (
    <main className="container flex items-center justify-center">
      <section className="w-[90%] md:w-[360px] bg-white shadow-lg min-h-[380px] rounded-2xl flex flex-col py-4">
        <div className="py-2 px-4 grid grid-rows-2 grid-cols-5 gap-2 shadow-sm">
          <img src={avatar} className="row-span-2 ..." alt="avatar" />
          <h4 className="row-span-1 col-span-4 ... text-black">
            The Forgotten Adoption Option
          </h4>
          <p className="col-span-4 ... text-primary-800 text-sm">
            Support ChatBot
          </p>
        </div>
        <div
          ref={chatContainer}
          className="p-4 max-h-[250px] min-h-[250px] overflow-y-scroll bg-neutral-100 hide-scrollbar"
        >
          <BotReply text="How can I help you with your questions?" />
          {chats.map(item =>
            item.from === "user" ? (
              <UserReply key={uuidv4()} text={item.text} />
            ) : (
              <BotReply key={uuidv4()} text={item.text} />
            )
          )}
        </div>
        <form id="form" onSubmit={handleSubmit} className="flex px-4 pt-4">
          <input
            name="user-input"
            type="text"
            className="bg-transparent border border-primary-700 p-2 rounded-2xl w-full border-r-0 rounded-r-none"
            value={userInput}
            onChange={handleChange}
            required
          />
          <button
            type="submit"
            className="bg-transparent border text-primary-700 border-primary-700 p-2 rounded-2xl border-l-0 rounded-l-none"
          >
            <SendIcon fontSize="large" />
          </button>
        </form>
      </section>
    </main>
  );
}
