import { BrowserRouter } from "react-router-dom";
import ScrollToTopOnUrlChange from "./components/ScrollToTopOnUrlChange";
import { DataStoreProvider } from "./components/DataStoreProvider";
import Home from "./pages/Home";

function App() {
  return (
    <BrowserRouter>
      <ScrollToTopOnUrlChange />
      <DataStoreProvider>
        <Home />
      </DataStoreProvider>
    </BrowserRouter>
  );
}

export default App;
