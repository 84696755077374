import { contentTypes } from "../../utility/constants";

export default {
  type: contentTypes.TEXT,
  title: "The Celebration",
  content: (
    <article>
      <p>
        <strong>Gotcha Day</strong>
      </p>
      <p>
        For some, Gotcha Day or the day of the adoption hearing is a really big
        deal. For others, the spotlight isn’t wanted. Here are some things to
        consider as you think about how your want to design your family’s
        special milestone:
      </p>

      <ul>
        <li>
          <strong>Outfits & Signage -</strong> Do you want to coordinate your
          family’s outfits? Pinterest is a great place to get ideas. Do you want
          to have a special sign in the photos that states your child’s
          name/first name and a special message?
        </li>
        <li>
          <strong>Photographer -</strong> Do you want to hire a professional or
          ask a friend or family member to capture photos so you can be present
          throughout the day?
        </li>
        <li>
          <strong>Party -</strong> Do you want to throw a party before or after
          the court hearing? Who do you want to invite? What do you want to eat?
        </li>
      </ul>
    </article>
  ),
};
