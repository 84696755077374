import { contentTypes } from "../../utility/constants";

export default {
  type: contentTypes.PODCAST,
  title: "Worth Listening",
  playlist:
    "https://open.spotify.com/embed/playlist/5AGdb36LjF3n6zRQzxS4HN?si=b60c4b9df44d4613",
  resources: [
    {
      title: "Meet Jane - Adoptive Mom (when her son was 6-years-old!)",
      links: [
        {
          link: "https://podcasts.apple.com/us/podcast/meet-jane-adoptive-mom-when-her-son-was-6-years-old/id1542072701?i=1000505697267",
          platform: "apple",
        },
        {
          link: "https://spotifyanchor-web.app.link/e/fgHiifFQoub",
          platform: "spotify",
        },
      ],
    },
    {
      title: "Meet Robin - Adoptive Mom (of a teenager!)",
      links: [
        {
          link: "https://podcasts.apple.com/us/podcast/meet-robin-adoptive-mom-of-a-teenager/id1542072701?i=1000504468419",
          platform: "apple",
        },
        {
          link: "https://spotifyanchor-web.app.link/e/9pszTfFQoub",
          platform: "spotify",
        },
      ],
    },
    {
      title: "Meet Grandpa Bob - Adoptive Grandparent (and my dad!)",
      links: [
        {
          link: "https://podcasts.apple.com/us/podcast/meet-grandpa-bob-adoptive-grandparent-and-my-dad/id1542072701?i=1000512250724",
          platform: "apple",
        },
        {
          link: "https://spotifyanchor-web.app.link/e/CxHsZbFQoub",
          platform: "spotify",
        },
      ],
    },
    {
      title: "Meet Alice - Adoptive Mom (and one of my mentors!)",
      links: [
        {
          link: "https://podcasts.apple.com/us/podcast/meet-alice-adoptive-mom-and-one-of-my-mentors/id1542072701?i=1000515030332",
          platform: "apple",
        },
        {
          link: "https://spotifyanchor-web.app.link/e/GE4f3aFQoub",
          platform: "spotify",
        },
      ],
    },
    {
      title: "HOW to share your family's foster care adoption story",
      links: [
        {
          link: "https://podcasts.apple.com/us/podcast/how-to-share-your-familys-foster-care-adoption-story/id1542072701?i=1000569662157",
          platform: "apple",
        },
        {
          link: "https://spotifyanchor-web.app.link/e/aM3lhRsSoub",
          platform: "spotify",
        },
      ],
    },
  ],
};
