import {useEffect, useState} from "react";
import {KeyboardArrowUp} from '@mui/icons-material/';

const ScrollButton = function scrollButton(){
    const [isVisible, setIsVisible] = useState(false)

    const toggleVisible = () => {
        if (window.scrollY > 20) {
            if (window.innerWidth <= 600) {
                setIsVisible(true)
            }
        } else {
            setIsVisible(false)
        }
    }

    const scrollToTop = () =>{
        window.scrollTo({
            top: 0, 
            behavior: 'smooth'
        });
    };

    useEffect(() => {
        window.addEventListener('scroll', toggleVisible)

        return () => {
            window.removeEventListener('scroll', toggleVisible)
        }
    }, [])

    return(
        <div
            className={`fixed right-5 bottom-24 w-9 h-9 z-1250 bg-primary rounded-full ${isVisible ? 'visible' : 'collapse'}`}
            // margin: "25px", display: visible ? {xs:"inline",sm:"none"} : 'none', position: 'fixed', bottom: {xs:72, sm:72, md:20}, right: 20, zIndex:1250
        >
            <KeyboardArrowUp
                onClick={scrollToTop}
                fontSize="large"
                color="primary"
            />
        </div>
    )
}

export default ScrollButton;