import { Outlet } from "react-router";
import Steps from "../Steps";

export default function HomeLayout() {
  return (
    <main className="relative justify-center container mx-auto flex sm:grid sm:grid-cols-12 sm:gap-12">
      <Steps />
      <Outlet />
    </main>
  );
}
