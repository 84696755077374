import { contentTypes } from "../../utility/constants";

export default {
  type: contentTypes.TEXT,
  title: "Resources Available to Your Family",
  content: (
    <article>
      <p>
        <strong>Become Knowledgeable of Available Resources</strong>
      </p>
      <p>
        Finding resources available to foster families and families who have
        adopted through foster care in my area has been mainly by word of mouth.
        In other words, if you are wondering what resources are available to
        you, you might need to ask around. Here are examples of resources that I
        had access to:
      </p>

      <ul>
        <li>
          <strong>Food Stamps -</strong> Because we were legally foster parents
          to our children when they first moved in, we qualified for WIC (Women
          Infants & Children). I had no idea until a friend reached out via
          Facebook message notifying me because her job was to get families
          enrolled in food stamps. This was a great blessing to our food
          expenses, but it also required regular visits for my children to meet
          with a state nutritionist who helped guide and coach us on being
          intentional about the food we gave our children. Until both of my
          children were age 5 we were able to participate in this program. It
          was a humbling experience when we would go to the grocery store and
          check out because at the time the food vouchers were awarded as
          several checks, which took some patience from the cashier and fellow
          shoppers in line.
        </li>
        <li>
          <strong>Clothing Allowance -</strong> While in foster care, children
          are given a set reimbursable amount to spend on clothing. This means
          you can purchase clothing and submit the receipts for reimbursement.
          Each calendar year, each of our children was eligible for $290 in
          clothing expenses.
        </li>
        <li>
          <strong>Health Insurance -</strong> While in foster care and once
          adopted through the foster care system, children are eligible for
          state healthcare. The downsides are the network of providers is
          limited and your only option might be a walk-in clinic where you have
          to wait your turn to be seen. But if you ask around, you might be able
          to find some great providers. We have.
        </li>
        <li>
          <strong>Child Care -</strong> This fact blows my mind. Children who
          are in foster care and who have been adopted through foster care are
          eligible for “childcare assistance” until age 13. What this means is
          the state will pay for your child(ren) to go to certain daycares,
          preschools, summer camps, and before and after school programs.
        </li>
        <li>
          <strong>Food pantry -</strong> This one is a newer find for me. My
          area actually has a food pantry that gives food to foster families and
          those who have adopted through foster care. Shopping here means you’re
          at the luck of whatever is donated, but it is a way to cut expenses.
        </li>
        <li>
          <strong>Gifts -</strong> This resource varies by what is in your area,
          but in St. Louis, children who are in foster care and who have been
          adopted through foster care can receive school supplies, a Christmas
          gift, and free shopping at specific thrift stores.
        </li>
      </ul>
    </article>
  ),
};
