import { contentTypes } from "../../utility/constants";

export default {
  type: contentTypes.TEXT,
  title: "Write Down Your Why",
  description:
    "You'll need this the whole process - share PDF to power statement or typeable like Noom",
  content: (
    <article>
      <p>
        Similar to having a personal mission statement, taking time to write
        out, print, and have a copy to remind you of why you’re doing this will
        help keep you going even when the process gets long and the emotional
        roller coaster has you questioning why you even began this process.
      </p>
      <p>
        You can type or write this out. You can even get creative and add color
        and graphics to you.
      </p>
      <p>Consider answering these questions:</p>
      <ul>
        <li>
          What is your intention? Are you hoping to adopt? How many children?
          What ages?
        </li>
        <li>What do you want to adopt? What sparked this desire?</li>
        <li>What makes right now the right time to do this?</li>
        <li>How do you know this is the right path for you?</li>
        <li>
          Where will you turn when things are difficult or the future looks
          uncertain?
        </li>
      </ul>
    </article>
  ),
};
