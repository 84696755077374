import { contentTypes } from "../../utility/constants";

export default {
  type: contentTypes.READING,
  title: "Worth Reading",
  resources: [
    {
      title: "Are You a Forever Family?",
      links: [
        {
          link: "https://www.amazon.com/Are-Forever-Family-Marcy-Bursac/dp/166784072X",
          platform: "anchor",
        },
      ],
    },
  ],
};
