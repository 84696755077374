import { contentTypes } from "../../utility/constants";

export default {
  type: contentTypes.PODCAST,
  title: "Worth Listening",
  playlist:
    "https://open.spotify.com/embed/playlist/0zEWGKJFc7in8esy1Q8K59?si=eb4435966f96464c",
  resources: [
    {
      title: "Meet Big Brother & Little Sister - My Adopted Sibling Pair",
      links: [
        {
          link: "https://podcasts.apple.com/us/podcast/meet-big-brother-little-sister-my-adopted-sibling-pair/id1542072701?i=1000520614875",
          platform: "apple",
        },
        {
          link: "https://spotifyanchor-web.app.link/e/PV54M5zQoub",
          platform: "spotify",
        },
      ],
    },
    {
      title: "Meet Dalton - Adopted at Age 6",
      links: [
        {
          link: "https://podcasts.apple.com/us/podcast/meet-dalton-adopted-at-age-6/id1542072701?i=1000530500086",
          platform: "apple",
        },
        {
          link: "https://spotifyanchor-web.app.link/e/Dx2Wh1zQoub",
          platform: "spotify",
        },
      ],
    },
    {
      title: "Meet Mary - Adopted Child",
      links: [
        {
          link: "https://podcasts.apple.com/us/podcast/meet-mary-adopted-child/id1542072701?i=1000531909168",
          platform: "apple",
        },
        {
          link: "https://spotifyanchor-web.app.link/e/g9utg0zQoub",
          platform: "spotify",
        },
      ],
    },
    {
      title: "Meet Hunter - An Adopted Child (who is now an adult!)",
      links: [
        {
          link: "https://podcasts.apple.com/us/podcast/meet-hunter-an-adopted-child-who-is-now-an-adult/id1542072701?i=1000507435526",
          platform: "apple",
        },
        {
          link: "https://spotifyanchor-web.app.link/e/aV5bjdFQoub",
          platform: "spotify",
        },
      ],
    },
  ],
};
