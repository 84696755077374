import { contentTypes } from "../../utility/constants";

export default {
  type: contentTypes.TEXT,
  title: "The Struggles",
  content: (
    <article>
      <p>
        <strong>Anger</strong>
      </p>
      <p>
        Meltdowns. That’s what we called them for the longest time. Screaming,
        throwing, leaving the house, refusing to calm down. These were behaviors
        I saw on the regular. Sometimes it would be as we were trying to
        transition to drive to school and escalate into a kid screaming and
        kicking car ride. For five years we tried play therapy, cognitive
        therapy, and video game anger management training (we used Mightier, an
        app developed by Harvard and Boston Children’s Hospital). The meltdowns
        are not gone, but they are less frequent. Through this struggle, I have
        learned more about a coworker who felt like he brought anger issues into
        his adulthood, and I have been able to remember that life is not just
        about getting work done. It’s about processing feelings and being in the
        moment, too.
      </p>

      <p>
        <strong>Physical Boundaries</strong>
      </p>
      <p>
        When young children are not taught appropriate boundaries, they have
        blurred lines on family, friends, and strangers. Little Sister had not
        been taught boundaries, so early in we had to establish some rules. For
        example, she could only sit on the lap of mom, dad, or grandparents. It
        was hard at first for her to understand how to appropriately interact
        with adults, but with consistency the boundary was learned.
      </p>

      <p>
        <strong>Food</strong>
      </p>
      <p>
        We are not sure the quality of food that our children were fed, but we
        do know that Big Brother had a hard time eating more than pizza and
        chicken nuggets. At first, we wondered if he was a picky eater. However,
        with time and observation we learned that he did not know how to chew
        with his back teeth, so things like carrots were hard for him to chew.
        We took time to help him learn to chew with all his teeth, and while
        ketchup is still the only condiment he likes on anything, we have come a
        long way.
      </p>

      <p>
        <strong>Overshadowing</strong>
      </p>
      <p>
        Big Brother and Little Sister had lived in six other homes before ours.
        In at least one of the homes, Little Sister was favored, and Big Brother
        got overshadowed. Call it being the youngest or learned behavior or
        both, once Little Sister started talking, we noticed she would
        overshadow Big Brother. Desiring balance and equal airtime, we are
        trying to help Little Sister give equal time and attention to Big
        Brother.
      </p>
    </article>
  ),
};
