import { contentTypes } from "../../utility/constants";

export default {
  type: contentTypes.TEXT,
  title: "Online Listings",
  content: (
    <article>
      <p>
        Online you can find galleries or listings of children who are legally
        free to be adopted through foster care. These sites do not include every
        child that is waiting to be adopted for various reasons (i.e. the child
        might not want their peers to know they are looking for a forever
        family) and sometimes you might feel drawn to a child or group of
        children who are in the process of being adopted (read: the listings
        might not be up-to-date).
      </p>
      <p>
        However, there are places online where you can browse profiles of
        potential children who you might consider adopting including{" "}
        <a
          href="https://map.afamilyforeverychild.org/kids/All_Kids.php"
          target="_blank"
          rel="noopener noreferrer"
        >
          A Family for Every Child
        </a>
        ,{" "}
        <a
          href="https://www.heartgalleryofamerica.org/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Heart Gallery of America
        </a>{" "}
        and{" "}
        <a
          href="https://www.adoptuskids.org/meet-the-children/children-in-foster-care/children-on-adoptuskids"
          target="_blank"
          rel="noopener noreferrer"
        >
          AdoptUsKids
        </a>
        . Before you click these links, it is important to know that you can
        live in any state and adopt child(ren) from any state within the United
        States. If that was confusing, the bottom line is the state the
        child(ren) currently lives does not need to be the state where their
        forever family lives.
      </p>
    </article>
  ),
};
