import { contentTypes } from "../../utility/constants";

export default {
  type: contentTypes.TEXT,
  title: "Assess Your Home",
  content: (
    <article>
      <p>
        Evaluate if your home has enough bedrooms and beds for the child(ren)
        you are hoping to bring home. My home had one captain’s bed (a twin bed
        with drawers underneath built into the wall) in one bedroom but the
        other bedroom just had a desk. We needed to decide if we were going to
        get a frame and mattress for the other bedroom or if we wanted the rooms
        to match and hire someone to build a second captain’s bed. We opted to
        hire a carpenter to build a matching bed.
      </p>
      <p>
        When you begin the licensing process, it is not expected that you will
        be fully ready for child(ren). It is expected that you will prepare your
        home as needed (like adding beds, fire extinguishers, outlet covers,
        safety latches on cabinets, additional door locks for exterior doors so
        children cannot open them, etc.) by the time you finish the training
        process so that you are ready to care for child(ren) you may potentially
        join your family. What you will need to do to prepare depends on the
        age(s) of the child(ren) you care for. You may also need things like car
        seats, plastic utensils and plates, or even baby bottles.
      </p>
    </article>
  ),
};
