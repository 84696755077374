import { Link } from "react-router-dom";
import { Container } from "../components/UIComponents/Container";
import Breadcrumbs from "../components/UIComponents/Breadcrumbs";
import { ArticleHeader } from "../components/UIComponents/Typography";

export default function Preparation() {
  return (
    <main>
      <Container className="flex flex-col flex-nowrap justify-center max-w-[1200px] w-[95%] md:w-[80%]">
        <article className="relative">
          <Breadcrumbs
            separator="›"
            crumbs={["Home", "Get Started", "Preparation"]}
          />

          <ArticleHeader>Preparation</ArticleHeader>

          <p>
            Regardless of where you are in the process, there are a few things
            you can do at any time to get ready for adopting through foster
            care.
          </p>

          <h2>Evaluate Your Heart</h2>

          <p>
            Rita Soronen, President & CEO of Dave Thomas Foundation for
            Adoption, encourages people considering foster care adoption to ask
            themselves:
          </p>

          <ol className="mb-4 list-decimal pl-8">
            <li className="pl-2">
              Am I willing to learn about the dynamics of abuse and neglect and
              understand the kind of trauma a child experiences before moving
              into foster care, and often, after?
            </li>
            <li className="pl-2">
              Am I willing to commit to a child permanently?
            </li>
            <li className="pl-2">
              What kind of supportive networks do I have or am I willing to
              find?
              <sup>[1]</sup>
            </li>
          </ol>

          <p>You might find yourself also wondering:</p>

          <ol className="mb-4 list-decimal pl-8" start={4}>
            <li className="pl-2">What kind of home is “ready” to adopt?</li>
            <li className="pl-2">What would “ready” really look like?</li>
          </ol>

          <h2>Assess Your Home</h2>

          <p>
            Evaluate if your home has enough bedrooms and beds for the
            child(ren) you are hoping to bring home. My home had one captain’s
            bed (a twin bed with drawers underneath built into the wall) in one
            bedroom but the other bedroom just had a desk. We needed to decide
            if we were going to get a frame and mattress for the other bedroom
            or if we wanted the rooms to match and hire someone to build a
            second captain’s bed. We opted to hire a carpenter to build a
            matching bed. When you begin the licensing process, it is not
            expected that you will be fully ready for child(ren). It is expected
            that you will prepare your home as needed (like adding beds, fire
            extinguishers, outlet covers, safety latches on cabinets, additional
            door locks for exterior doors so children cannot open them, etc.) by
            the time you finish the training process so that you are ready to
            care for child(ren) you may potentially join your family. What you
            will need to do to prepare depends on the age(s) of the child(ren)
            you care for. You may also need things like car seats, plastic
            utensils and plates, or even baby bottles.
          </p>

          <h2>Put Together a Family Photo Album</h2>

          <p>
            You will want to put together a picture book about your family. This
            can include pictures of who is in your family (people and pets),
            things you enjoy doing, and pictures of nearby parks where the
            child(ren) might play. My family’s book was an ABC book where each
            letter included a picture and short paragraph about our lives. We
            got this printed through a website and ordered 8-10 copies so we
            could have one for each child, one for the staffing, and a few
            backup copies just in case we went through the process multiple
            times and needed to have a copy of the book handy.
          </p>

          <h2>Federal Tax Credits </h2>

          <p>
            Consider how your family will use the federal adoption tax credit.
            As of 2022, the federal adoption tax credit is $14,440 per child for
            children adopted through foster care. You can use this credit
            however you please; be it setting up a college savings account, for
            expenses of raising the child, whatever you want.
          </p>

          <h2>Test Drive</h2>

          <p>
            Consider trying <Link to="/get-your-feet-wet"> these options</Link>{" "}
            to get your feet wet.
          </p>

          <hr className="bg-primary my-3" />

          <p>
            [1]{" "}
            <p className="text-xs inline">
              Soronen, R. (2019, December 5). Children are waiting: Fostering
              and adopting children from foster care. Dave Thomas Foundation for
              Adoption. Retrieved August 3, 2020 from:
              https://www.davethomasfoundation.org/children-are-waiting-fostering-and-adopting-from-foster-care/.
              Used with permission.
            </p>
          </p>
        </article>
      </Container>
    </main>
  );
}
