import { Link } from "react-router-dom";
import { Container } from "../components/UIComponents/Container";
import Breadcrumbs from "../components/UIComponents/Breadcrumbs";
import { ArticleHeader } from "../components/UIComponents/Typography";

export default function PageNotFound() {
  return (
    <main>
      <Container className="flex flex-col flex-nowrap justify-center max-w-[1200px] w-[95%] md:w-[80%] mb-32">
        <article className="relative">
          <Breadcrumbs separator="›" crumbs={["Home", "Page Not Found"]} />

          <ArticleHeader>Page Not Found</ArticleHeader>

          <p>
            Sorry, we can&apos;t seem to find that page. Maybe these will help
            get you where you were going:
          </p>

          <p>
            <Link to="/">Home</Link>
          </p>
          <p>
            <Link to="/quick-links">Quick Links</Link>
          </p>
          <p>
            <Link to="/about">About This App</Link>
          </p>
        </article>
      </Container>
    </main>
  );
}
