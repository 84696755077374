import { contentTypes } from "../utility/constants";

export default {
  type: contentTypes.TEXT,
  title: "No Content To Display",
  content: (
    <article>
      <p>
        Sorry, there isn&apos;t any content at this address.
        <br />
        <br />
        Please make a selection from the menu.
      </p>
    </article>
  ),
};
