export default {
  title: "Become aware of what foster care adoption is",
  content: [
    "What is foster care adoption? First, let s take a look at the reasons why foster care exists.",
    "In a sentence, when a child s home life is unsafe, dangerous or abusive, in order for the child to be safe and free of danger or abuse, the child needs a place to live.",
    "In the 1800 s in the United States, if a child s family could not take care of them they were sent to live with a relative or neighbor. As more and more people immigrated to the United States, orphanages sprouted up around the country as a way to provide a safe haven for groups of children who lost their parents due to illness and for children who were given up by their families because the family was in poverty struggling with drugs or alcohol addiction.",
    "About 100 years later, in the 1920 s, after seeing the positive differences between children living with a family versus living in a group setting, foster homes emerged in the United States.",
    "The intent of foster homes was and continues to be to provide children with “a safe, supportive and loving home” ... “while their parents of origin improve their ability to care for their children, remove any threats to the child’s safety and well-being from the environment and,ultimately, reunite with their children”",
    "You read that right, the goal of foster care is to have children move back in with their biological families. Sometimes, just like New Year s Resolutions, goals are goals that never materialize into real-life achievements. Children who move into a foster home may never move back in with their biological families, or at least permanently. This occurs, for instance, when the biological parents are unable to develop the appropriate parenting skills, become safe, or relapse over and over again on a drug addiction.",
    "Some children become legally free for adoption while in foster care. Let me say this a different way, there are children who are ready to be adopted and you can adopt them without being a foster parent. That means their biological parents lose the ability to be the child’s parents and the state takes over as the child’s legal guardian until the child is adopted. The need for adoptive homes is great. At the time of launching this app, more than 113,000 children are in this situation. The ages of the children are as young as just born to a senior in high school at age 18. Some are all by themselves others are with a sibling or two or more.",
  ],
};
