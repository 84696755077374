import * as NavigationMenu from "@radix-ui/react-navigation-menu";
import { CaretDownIcon } from "@radix-ui/react-icons";
import { Link } from "react-router-dom";

interface iSubMenu {
  title: React.ReactNode;
  submenu: {
    title: string;
    endpoint: string;
  }[];
}

export default function HeaderSubMenu({ title, submenu }: iSubMenu) {
  return (
    <NavigationMenu.Item>
      <NavigationMenu.Trigger className="text-primary group flex select-none items-center justify-between gap-[2px] outline-none">
        {title}{" "}
        <CaretDownIcon className="relative transition-transform duration-[250] ease-in group-data-[state=open]:-rotate-180" />
      </NavigationMenu.Trigger>
      <NavigationMenu.Content className="absolute drop-shadow-md bg-background rounded-md data-[state=open]:animate-scaleIn data-[state=closed]:animate-scaleOut">
        {submenu.map((subItem, idx) => {
          // Add rounded corners for top and bottom subItem elements
          const defaultStyling = "block p-4 hover:bg-primary-100 text-primary";
          let subItemStyling = "";
          switch (idx) {
            case 0:
              subItemStyling = `hover:rounded-t-md ${defaultStyling}`;
              break;
            case submenu.length - 1:
              subItemStyling = `hover:rounded-b-md ${defaultStyling}`;
              break;
            default:
              subItemStyling = defaultStyling;
          }

          return (
            <NavigationMenu.Link key={subItem.title} asChild>
              <Link to={subItem.endpoint} className={subItemStyling}>
                {subItem.title}
              </Link>
            </NavigationMenu.Link>
          );
        })}
      </NavigationMenu.Content>
    </NavigationMenu.Item>
  );
}
