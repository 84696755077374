// eslint-disable-next-line import/extensions
import step1Task1 from "./step1_task1_data.js";
// eslint-disable-next-line import/extensions
import step1Task2 from "./step1_task2_data.js";
// eslint-disable-next-line import/extensions
import step1Task3 from "./step1_task3_data.js";
// eslint-disable-next-line import/extensions
import step1Task4 from "./step1_task4_data.js";
// eslint-disable-next-line import/extensions
import step1Task5 from "./step1_task5_data.js";

const STEP1TASK1 = step1Task1.content;
const STEP1TASK2 = step1Task2.content;
const STEP1TASK3 = step1Task3.content;
const STEP1TASK4 = step1Task4.content;
const STEP1TASK5 = step1Task5.content;

const WPM = 238;
function WordCount(str) {
  return str.split(" ").length;
}

function taskCount(arr) {
  let totalWord = 0;
  for (let i = 0; i < arr.length; i += 1) {
    totalWord += WordCount(arr[i]);
  }
  return totalWord;
}

const MINREADSTEP1TASK1 =
  Math.round(taskCount(STEP1TASK1) / WPM) > 0
    ? Math.round(taskCount(STEP1TASK1) / WPM)
    : 1;

const MINREADSTEP1TASK2 =
  Math.round(taskCount(STEP1TASK2) / WPM) > 0
    ? Math.round(taskCount(STEP1TASK2) / WPM)
    : 1;

const MINREADSTEP1TASK3 =
  Math.round(taskCount(STEP1TASK3) / WPM) > 0
    ? Math.round(taskCount(STEP1TASK3) / WPM)
    : 1;

const MINREADSTEP1TASK4 =
  Math.round(taskCount(STEP1TASK4) / WPM) > 0
    ? Math.round(taskCount(STEP1TASK4) / WPM)
    : 1;

const MINREADSTEP1TASK5 =
  Math.round(taskCount(STEP1TASK5) / WPM) > 0
    ? Math.round(taskCount(STEP1TASK5) / WPM)
    : 1;
export {
  MINREADSTEP1TASK1,
  MINREADSTEP1TASK2,
  MINREADSTEP1TASK3,
  MINREADSTEP1TASK4,
  MINREADSTEP1TASK5,
};
