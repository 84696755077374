export default {
  title: "Get Professional Help for Wounds",
  content: [
    "Vulnerability is not about fear and grief and disappointment. It is the birthplace of everything we re hungry for.",
    "Dealing with your stuff is no small task. Some of us grew up being encouraged to share our feelings and experiences, while others of us felt things but never shared them with anyone for one reason or another. Unpacking those tucked away memories can conjure up other insecurities and fears that you might not even have known were inside you.",
    "Debra, an adoptive parent (whose biological daughter and I were in college together), shares these words: If you re [trying to adopt] to feel love, you re in it for the wrong reasons. It s a commitment. You ust have to love and you don t necessarily get the love back.",
    "What are things you need to get emotionally clear on? What steps can you take to get the support and professional help you need to work through these things?",
    "Author and pastor Rick Warren encourages people who are desiring a committed relationship (read: marriage, dating, adopting a child) to not move forward “until your own emotional hurts are healed or at least in the healing process. Get rid of the bitterness. Get rid of the anger problem. Get rid of your baggage.",
    "Being an adoptive parent requires that you be available to help support your children through their own wounds. If you can address yours, you are in a better position to speak from experience, walk with them, and encourage them to work through theirs.",
    "Finding a therapist or engaging in mental health services can feel overwhelming. Asking trusted friends and family for their recommendations can be helpful. If you have medical insurance, you can contact your insurance company to get a list of in-network providers in your state. If you prefer virtual visits, betterhelp.com comes recommended by a friend who is a therapist.",
  ],
};
