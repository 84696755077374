import { contentTypes } from "../../utility/constants";
import { TextCaption } from "../../components/UIComponents/Typography";

export default {
  type: contentTypes.TEXT,
  title:
    "Ask Yourself (and your significant other if applicable) these questions",
  content: (
    <article>
      <p>
        Rita Soronen, President & CEO of Dave Thomas Foundation for Adoption,
        encourages people considering foster care adoption to ask themselves:
      </p>

      <ol>
        <li>
          Am I willing to learn about the dynamics of abuse and neglect and
          understand the kind of trauma a child experiences before moving into
          foster care, and often, after?
        </li>
        <li>Am I willing to commit to a child permanently?</li>
        <li>
          What kind of supportive networks do I have or am I willing to find?
          <sup>[1]</sup>
        </li>
      </ol>

      <p>You might find yourself also wondering:</p>

      <ol>
        <li>What kind of home is “ready” to adopt?</li>
        <li>What would “ready” really look like?</li>
      </ol>

      <p>
        [1]{" "}
        <TextCaption>
          Soronen, R. (2019, December 5). Children are waiting: Fostering and
          adopting children from foster care. Dave Thomas Foundation for
          Adoption. Retrieved August 3, 2020 from:
          https://www.davethomasfoundation.org/children-are-waiting-fostering-and-adopting-from-foster-care/.
          Used with permission.
        </TextCaption>
      </p>
    </article>
  ),
};
