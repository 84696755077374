import { contentTypes } from "../../utility/constants";

export default {
  type: contentTypes.TEXT,
  title:
    "Create Your Own Family Book to Introduce Your Family to the Interview Team",
  content: (
    <article>
      <p>
        You will want to put together a picture book about your family. This can
        include pictures of who is in your family (people and pets), things you
        enjoy doing, and pictures of nearby parks where the child(ren) might
        play.{" "}
        <a
          href="https://drive.google.com/drive/folders/1p-2yKt28H4L1KZQlrZkagHxVUDUyTOkH?usp=sharing"
          target="_blank"
          rel="noopener noreferrer"
        >
          My family’s book
        </a>{" "}
        was an ABC book where each letter included a picture and short paragraph
        about our lives. We got this printed through a website and ordered 8-10
        copies so we could have one for each child, one for the staffing, and a
        few backup copies just in case we went through the process multiple
        times and needed to have a copy of the book handy.
      </p>
      <a
        href="https://drive.google.com/drive/folders/1p-2yKt28H4L1KZQlrZkagHxVUDUyTOkH?usp=sharing"
        target="_blank"
        rel="noopener noreferrer"
      >
        Our Family Book
      </a>
    </article>
  ),
};
