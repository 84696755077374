import * as Toast from "@radix-ui/react-toast";

export default function ErrorPopUp({
  error,
  setError,
}: {
  error: string;
  setError: React.Dispatch<React.SetStateAction<string>>;
}) {
  const handleOpenChange = () => {
    setError("");
  };

  return (
    <Toast.Provider swipeDirection="right">
      <Toast.Root
        open
        onOpenChange={handleOpenChange}
        onSwipeEnd={() => {
          setError("");
        }}
      >
        <Toast.Title className="text-lg">
          Oops! Looks like there was an issue:
        </Toast.Title>
        <Toast.Description className="italic my-2">
          {error.replace("FirebaseError: Firebase: ", "")}
        </Toast.Description>
        <Toast.Action asChild altText="Dismiss error">
          <button
            type="button"
            className="rounded border border-black m-2 px-2 float-right"
            onClick={() => {
              setError("");
            }}
          >
            Dismiss
          </button>
        </Toast.Action>
      </Toast.Root>
      <Toast.Viewport className="fixed top-24 right-2 md:right-5 left-2 md:left-auto p-3 z-30 rounded bg-white border border-3 border-primary-500 shadow-xl" />
    </Toast.Provider>
  );
}
