import { contentTypes } from "../../utility/constants";

export default {
  type: contentTypes.TEXT,
  title: "Who Is Around the Table",
  content: (
    <article>
      <p>
        Once you are licensed you can ask for more information on children who
        are available for adoption. The online or initial profile you’ll read is
        called a short profile. After reading the short profile you can request
        more information called the long profile. If you have reviewed the long
        profile and are interested in potentially adopting the child, you talk
        to your licensing worker, and they submit your home study.
      </p>
      <p>
        Of all the home studies submitted for a child/sibling set, 2-6 or more
        families can be invited to an interview (this is called a “staffing”).
        At the interview the table can be filled with the child(ren)’s current
        social worker, therapist, CASA (Court Appointed Special Advocate), DJO
        (Deputy Juvenile Officer - this person acts as the voice of the State on
        behalf of the child(ren)), the current foster parents, and various
        community members. Within 24 hours of the staffing (interview) the
        families interviewed find out if they have been selected as the best fit
        for the children. If you are selected, you work with the child(ren)’s
        social worker, therapist, etc. to develop a transition plan to meet the
        children, have them over, and eventually have them live in your home.
        After the child(ren) have been living with you for 6 months you can go
        before a judge and legally adopt them. In some situations, the
        experience of living together is not a good fit, and the children move
        out and then go through the whole process of being matched again.
      </p>
    </article>
  ),
};
