import Breadcrumbs from "./UIComponents/Breadcrumbs";
import { Container } from "./UIComponents/Container";
import testimonials from "../content/testimonialContent";
import TestimonialCard from "./TestimonialCard";
import { ArticleHeader } from "./UIComponents/Typography";

export default function Testimonials() {
  return (
    <main>
      <Container className="prose-link prose-img:m-0 flex flex-col flex-nowrap justify-center max-w-[1200px] w-[95%] md:w-[80%]">
        <article className="relative flex flex-col flex-nowrap items-center">
          <Breadcrumbs
            separator="›"
            crumbs={["Home", "About", "Testimonials"]}
          />

          <ArticleHeader>Testimonials</ArticleHeader>

          {testimonials.map(testimonial => (
            <TestimonialCard key={testimonial.alt} cardData={testimonial} />
          ))}
        </article>
      </Container>
    </main>
  );
}
