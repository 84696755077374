import { ExternalLinkIcon } from "@radix-ui/react-icons";

interface iTestimonialCardProps {
  cardData: {
    img: string;
    alt: string;
    attribution: string;
    podcastLink: string;
    content: string;
  };
}

export default function TestimonialCard({ cardData }: iTestimonialCardProps) {
  const { img, alt, attribution, podcastLink, content } = cardData;

  return (
    <div className="max-w-[600px] mb-12 border-[10px] rounded-md border-primary/25 shadow-none">
      <img src={img} alt={alt} className="max-h-[400px]" />
      <div className="p-4 border-t-[10px] flex flex-col border-t-primary/25">
        <p className="leading-relaxed">
          <em>{content}</em>
        </p>
        <i className="ml-auto text-sm md:mr-3 my-4">{attribution}</i>
      </div>
      <div className="prose-a:prose-link flex pt-2 items-center justify-center bg-primary/25">
        <a
          href={podcastLink}
          target="_blank"
          rel="noopener noreferrer"
          className="flex gap-1 items-center py-1 text-primary-500"
        >
          <ExternalLinkIcon />
          Listen to the whole story
        </a>
      </div>
    </div>
  );
}
