import { Container } from "../components/UIComponents/Container";
import { ArticleHeader } from "../components/UIComponents/Typography";

export default function Welcome() {
  return (
    <Container className="prose-img:m-0 prose-link max-w-none col-span-8 flex flex-col pt-0">
      <div className="relative flex justify-end h-[200px] before:content[' '] before:absolute before:right-[5.5rem] before:w-[80%] before:h-full before:bg-gradient-to-l before:from-primary">
        <img
          src="./images/welcomeHeadshot-200.png"
          alt="Marcy wearing an adopt shirt"
          className="w-[200px] h-[200px] z-[1]"
        />
      </div>

      <article>
        <ArticleHeader>Welcome</ArticleHeader>

        <p>
          Welcome to The Forgotten Adoption Option App - your guide for adopting
          child<i>(ren)</i> through the foster care system!
        </p>
        <p>
          This free application is a companion for the book{" "}
          <a
            href="https://www.amazon.com/Forgotten-Adoption-Option-Self-Reflection-How/dp/1098335376/"
            target="_blank"
            rel="noopener noreferrer"
            className="font-bold text-primary"
          >
            “The Forgotten Adoption Option: A Self-Reflection and How-To Guide
            for Pursuing Foster Care Adoption”
          </a>
        </p>
        <p>
          By teasing out all the to-do items from the book, my global volunteer
          team and I hope you can understand how to adopt waiting children in a
          concise way so that children who are waiting will one day meet you -
          their forever family.
        </p>
        <p>
          On the left, you’ll see 8 steps to the process. Clicking each step
          gives you a short list of things to do to prepare. These can range
          from increasing your knowledge about the child<i>(ren)</i> you will
          adopt and discussion questions to activities like making a family book
          or preparing your home to pass the home study.
        </p>
        <p>
          Along the top, we added quick access to browser profiles of waiting
          children and some worthwhile links like FAQs and ways to Get Your Feet
          Wet.
        </p>
        <p>
          Deciding to adopt and navigating this process are very heavy life
          decisions. I have traveled this path adopting my sibling pair who are
          now middle schoolers. As you jump in or browse around, I am eager to
          be a listening ear and guide. Just use the Contact button at the
          bottom to send me a direct message.
        </p>
        <p>Cheering you on,</p>
        <p className="font-bold">
          Marcy Bursac <br />
          <span className="uppercase text-sm">
            Adoptive Mom, Author & Podcast Host <br />
          </span>
        </p>
      </article>
    </Container>
  );
}
