import { contentTypes } from "../../utility/constants";

export default {
  type: contentTypes.TEXT,
  title: "Firsts",
  content: (
    <article>
      <p>
        While my children and I did not meet at their birth, I have had the
        incredible honor of being present for many of their firsts, including:
      </p>

      <ul>
        <li>riding a bike</li>
        <li>swimming underwater</li>
        <li>swimming in the deep end</li>
        <li>diving off a diving board</li>
        <li>hot fudge sundae</li>
        <li>playing hot potato</li>
        <li>getting a shirt off by yourself</li>
        <li>spraying whipped cream by yourself</li>
        <li>water park</li>
        <li>roller coaster</li>
        <li>beach</li>
        <li>Disney World</li>
        <li>vacation</li>
        <li>catching a fish</li>
        <li>kayaking</li>
        <li>martial arts</li>
        <li>camping</li>
        <li>winning an essay contest</li>
        <li>sledding</li>
        <li>building a snowman</li>
        <li>building a snow fort</li>
        <li>winning an art contest</li>
        <li>learning to read</li>
        <li>going to school</li>
        <li>riding a horse</li>
        <li>learning how to add</li>
        <li>buying something with their own money</li>
        <li>slumber party</li>
        <li>cutting their own food</li>
        <li>trip to the zoo</li>
        <li>trip to the art museum</li>
        <li>going on an escalator</li>
        <li>making a Build-a-Bear creation</li>
        <li>seeing a circus</li>
        <li>packing meals for people in need</li>
        <li>skateboarding</li>
        <li>jump roping</li>
        <li>using sidewalk chalk</li>
        <li>and many more to come...</li>
      </ul>
    </article>
  ),
};
