export default {
  title: "",
  content: [
    "You’ll want to decide which type of commitment you’re wanting to make so you know what type of licensing you’re aiming for[1]. This helps you decide the type of training you need.",
    "Type of Care/License Commitment Leads to Adoption Training Required",
    "Adopt-Only: Legally Free Child(ren) for life yes foster care training & adoption training",
    "Adopt-Only: Legal Risk Child(ren)	potentially indefinitely but not guaranteed	potentially but not guaranteed	foster care training & adoption training",
    "Foster Care a few days, a few months, temporary or indefinitely sometimes foster care training",
    "Kinship temporary or permanent	sometimes varies by state",
    "Respite a few hours - a few days sometimes	varies by state, might be online videos",
    "Adopt",
    "This option means you are interested in adopting a child or children. The child(ren) could be in the custody of the state because their biological parents parental rights have been terminated. These children are legally free to be adopted, meaning they have no risk of returning to their biological family. Or, the child(ren) could be “legal risk” which means they are nearing being legally free to be adopted but at the moment, there are a lot of legal processes that are still up in the air. The child(ren) is considered legal risk because while the current status looks like they will need an adoptive home, the child(ren)s biological family could start demonstrating signs of stability and/or safety. If you choose to pursue an adopt-only license (also known as foster-to-adopt in some areas) you will need to complete both foster care and adoptive care licensing requirements. Some call this “dual licensing.",
    "Foster",
    "Obtaining a foster license means you would like to open your home to be a temporary, loving home for children who have experienced trauma or abuse. While in your care, the child(ren) might return to their biological family (reunification) or they might become eligible for adoption. If the child(ren) becomes eligible for adoption, as the child(ren)’s foster family you can be considered but you are not guaranteed to be the adoptive home. Many factors are considered, including but not limited to if someone in the child(ren)’s extended family steps forward to adopt, or if another family is a better fit due to cultural or emotional needs.",
    "Kinship",
    "Kinship is the term for when a family member (grandparent, aunt/uncle, etc.) steps in to take care of a child(ren). Some kinship placements are for a few weeks or months while others are indefinite. It depends on the stability and safety of the biological family. Think foster care but with biological extended family members.",
    "Respite",
    "Respite is a way to provide a break for foster parents. You take child(ren) for an overnight to up to two weeks at a time. Foster Parents are given so many days of respite care per calendar year. Read: You get paid to be a respite provider for each day of care you provide. Respite is a great place to start if you are unsure and/or if you want to pursue adopt-only (again this is known as foster-to-adopt in some areas) but you are not currently a parent. My husband and I were respite providers and took care of a pair of brothers and a single boy on a few occasions. We provided respite care for five days once, three days once, and then two days once.",
  ],
};
