import { Container } from "../components/UIComponents/Container";
import Breadcrumbs from "../components/UIComponents/Breadcrumbs";
import { ArticleHeader } from "../components/UIComponents/Typography";

export default function WhileYouWait() {
  return (
    <main>
      <Container className="flex flex-col flex-nowrap justify-center max-w-[1200px] w-[95%] md:w-[80%]">
        <article className="relative">
          <Breadcrumbs separator="›" crumbs={["Home", "While You Wait"]} />
          <ArticleHeader>While You Wait</ArticleHeader>

          <p>
            The foster care adoption process is full of delays throughout the
            journey. You might be waiting for licensing classes to begin, for
            court hearings to get scheduled, for a document from another state
            to get processed… the list goes on and on.
          </p>

          <p>Don’t let the lengthening process get your spirit down.</p>

          <p>
            Remind yourself WHY you are doing this and then try to knock out
            some of these things that you can do while you wait:
          </p>

          <h2>Collect Documents</h2>

          <p>
            The full list of required documents varies state to state, but don’t
            have to wait until the end of your licensing classes to collect all
            these items and give them to your licensing worker. I was so gung-ho
            about the process, during our first home visit I handed our
            licensing worker a stack of papers for almost every item on this
            list. (Our medical physicals were scheduled so we didn’t have that
            part done yet.) I later found out that no one in her office had ever
            had a family do that.
          </p>

          <p>
            Here’s{" "}
            <a
              href="https://docs.google.com/file/d/1NUJAQgxwbq7XEkGcj3i4O3f5l3WVkjfs/edit?filetype=msword"
              target="_blank"
              rel="noopener noreferrer"
            >
              an example of the documents
            </a>{" "}
            your state might require before your home study can be completed.
          </p>

          <h2>Read Parenting Books</h2>

          <p>
            Specifically search for books about parenting children who have
            experienced neglect, abuse or trauma. I highly recommend{" "}
            <a
              href="https://www.amazon.com/Connected-Child-healing-adoptive-family/dp/0071475001"
              target="_blank"
              rel="noopener noreferrer"
            >
              “The Connected Child”
            </a>{" "}
            by Dr. Karyn Purvis.
          </p>

          <h2>Dream With Your Significant Other</h2>

          <p>
            Honestly and openly discuss characteristics you and your significant
            other are and are not willing to parent. Pages 8 & 9 of{" "}
            <a
              href="https://drive.google.com/file/d/1GEZTDG2SzB-mK6hjauz0lZwGN5BuPwRE/view"
              target="_blank"
              rel="noopener noreferrer"
            >
              this Inventory
            </a>{" "}
            contains several situations worth discussing. It is a good idea to
            be aligned on expectations before a need arises and one of you wants
            to say yes and the other is not on the same page.
          </p>
        </article>
      </Container>
    </main>
  );
}
